import GeneralTable from "../GeneralTable";
import Delete from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
/**
 * Componente que consulta la tabla de perfiles
 * requiere el objeto de perfiles
 * @param {object} profile 
 * @returns 
 */
const ProfileTable = ({profiles,onDelete,onEdit}) =>{

    return(
        <>
            <GeneralTable
                title="Perfiles"
                data={profiles}
                columns={[
                    { title: 'Perfil', field: 'name' },
                    { title: 'Descripción', field: 'description' },
                    { title: 'Fecha de creación', field: 'createdAt', type: 'date' },
                ]}
                /**
                 * Acciones, solo se usa eliminar
                 */
                actions={[
                    {
                      icon: () => <Delete/>,
                      tooltip: 'Eliminar perfil',
                      onClick: (event, rowData) => onDelete(rowData)
                    },
                    {
                        icon: () => <EditIcon/>,
                        tooltip: 'Editar perfil',
                        onClick: (event, rowData) => onEdit(rowData)
                      }
                ]}
            />
        </>
    )
}

export default ProfileTable; 