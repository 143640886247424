import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@material-ui/core"
import { useEffect, useState } from "react";


/**
 * Componente que muestra el filtro de la tabla
 */
const CustomFilter = ({title,field,isSelect,list,filterFunc,isArray,keys,value}) =>{

    useEffect(()=>{

      
            setSelectValue(value ? value: []);
        
        
    }
    ,[value]);

    const [selectValue,setSelectValue] = useState(value ? value: []);
    /**
     * Manda el valor seleccionado o utilizado para el filtro
     */
    const handleChange = (e) =>{
        let value  = e.target.value;

        
        if(isSelect){
            setSelectValue(e.target.value);
            filterFunc(field,e.target.value);
        }else{
            filterFunc(field,e.target.value);
        }
        
    }
    return(
        <Grid container >
            {
                !isSelect ? 
                <Grid item xs={12}>
                    
                    <TextField
                        label={title}
                        fullWidth
                        onChange={handleChange}
                    />
                </Grid>
                :
                <>
               
                    <InputLabel style={{width:'100%'}} id="demo-simple-select-label">{title}</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectValue}
                        label={title}
                        multiple
                        fullWidth
                        onChange={handleChange}
                        style={{width:'100%'}}
                    >
                        {list?.map((item) => {
                            if(isArray){
                                return( <MenuItem key={item} value={item}>{item}</MenuItem>)
                            }else{
                                return( <MenuItem key={item[keys[0]]} value={item[keys[0]]}>{item[keys[1]]}</MenuItem>)
                            }
                        
                        
                        })}
                    </Select>
               
                     
                </>
              
            }
           
        </Grid>
    )
}

export default CustomFilter;