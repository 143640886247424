import TablaGeneral from "../../components/TablaGeneral";
import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, InputLabel, MenuItem, Paper, Select, TextField, useTheme } from "@material-ui/core";
import { Box } from "@mui/system";

import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from "react";
import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";
import api from "../../services/api";
import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from "@material-ui/core/colors";
import Loading from "../../components/Loading";
import { SnackbarProvider } from "notistack";
import Edit from "@material-ui/icons/Edit";

const Perfiles = (props) =>{
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies(['token']);
  const { decodedToken, isExpired } = useJwt(cookies?.token);
  const [permissions,setPermissions] = useState([]);
  const [profiles,setProfiles] = useState([]);
  const [profileName,setProfileName] = useState(null);
  const [profileDesc,setProfileDesc] = useState(null);
  const [profileId,setProfileId] = useState(0);
  const [currentPerm,setCurrentPerm] = useState([]);
  const [tokenJwt] = useState(cookies.token);
 

  const [loading,setLoading] = useState(true);
  const [reload,setReload] = useState(false);
  const msg = useRef();


  useEffect(() => {
    async function getProfiles(token){
        try{
            let req = {token:token}
            let {data} = await api.post('api/profilelist/',req);
            setProfiles(data);
            setLoading(false);
        }catch(err){
          errorMsg("Error al consultar perfiles","error");
          setLoading(false);
        }
    }
    async function getPermission(token){
        try{
            let req = {token:token}
            let {data} = await api.post('api/permissionlist/',req);
            setPermissions(data);

        }catch(err){
          errorMsg("Error al consultar permisos","error");
          console.log(err);
        }
    }


    if(tokenJwt){

        getProfiles(tokenJwt);
        getPermission(tokenJwt);
    }
    setReload(false);
  },[tokenJwt,reload]);

  const handleOpen = (openVal) => {
    setOpen(openVal);
    setProfileDesc("");
    setProfileName("");
    setCurrentPerm([]);
    setProfileId(0);
  }

  const content = () => {
  
  
    return(
      <>
      <SnackbarProvider ref={msg} maxSnack={7} />
        <Loading open={loading}/>
        <Box sx={{ m: 2 }}  boxShadow={10}>

            <Paper elevation={20} >
            
                <TablaGeneral 
                        title={"Perfiles"}
                        columns= {[
                        { field: 'name', title: 'Perfil' },
                        { field: 'description', title: 'Descripción'},
                    ]}
                    data={profiles}     
                    options={{
                      headerStyle: {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                      },
                    }}  
                    actions={[
                        {
                          icon: () => <DeleteIcon sx={{ color: red[300] }}/>,
                          tooltip: 'Eliminar perfil',
                          onClick: (event, rowData) => {deleteProfile(rowData.id)}
                        },
                        {
                          icon: () => <Edit sx={{ color: red[300] }}/>,
                          tooltip: 'Actualizar perfil',
                          onClick: (event, rowData) => {updateProfile(rowData)}
                        }
                      ]}   
                />
            </Paper>
            <Box sx={{ m: 2 }}  boxShadow={3}>
              <Grid container justifyContent="flex-end">
                
                <Fab color="primary" aria-label="add" onClick={() => handleOpen(true)}>
                  <AddIcon />
                </Fab>
              </Grid>
            </Box>
        </Box>
      </>
    );
  }

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setCurrentPerm(value);
  };

  const addProfile = async () => {
        setLoading(true);
        if(!profileName){
            errorMsg("El nombre es requerido");
            setLoading(false);
            return false;
        }
        if(!profileDesc){
            errorMsg("La descripción es requerida");
            setLoading(false);
            return false;
        }
        if(currentPerm.length < 1){
            errorMsg("Debe agregar al menos un permiso");
            setLoading(false);
            return false;
        }
        handleOpen(false);
        
        try{
            let req = {
                token:tokenJwt,
                name: profileName,
                description: profileDesc
            }
           
            let {data} = await api.post('api/profile/',req);
            
            try{
                let req = {
                    token:tokenJwt,
                    profile: data.id,
                    permissions: currentPerm
                }
                console.log(req);
                await api.post('api/relationPerMassive/',req);
                errorMsg("Perfil agregado","success");
                setLoading(false);
                setReload(true);
            }catch(err){
              console.log(err);
              errorMsg("Error al agregar permisos","error");
              setLoading(false);
            }
        }catch(err){
          console.log(err);
          errorMsg("Error al crear perfil","error");
          setLoading(false);
        }
       
  };

  const errorMsg = (msg1,variant='warning') =>{
   
    msg.current.enqueueSnackbar(msg1, {
      variant: variant,
    });
  }
  const deleteProfile = async (profid) =>{
    
    setLoading(true);
    try{
        let req = {
            token:tokenJwt,
            profile: profid,
        }
       ;
       await api.post('api/profiledel/',req);
       setLoading(false);
       setReload(true);
       errorMsg("Perfil eliminado","success");
    }catch(err){
      setLoading(false);
      errorMsg("Error al eliminar perfil","error");
    }
}

    const updateProfile = async (data) =>{
    
      setProfileDesc(data.description);
      setProfileName(data.name);
      setProfileId(data.id);
      let perArr = [];

      data.Permissions.map((value) => {
        perArr.push(value.id);
      })
      
      setCurrentPerm(perArr);
      setOpen(true);
    }
    const updateProfileAction = async () =>{
      if(!profileName){
          errorMsg("El nombre es requerido");
          setLoading(false);
          return false;
      }
      if(!profileDesc){
          errorMsg("La descripción es requerida");
          setLoading(false);
          return false;
      }
      if(currentPerm.length < 1){
          errorMsg("Debe agregar al menos un permiso");
          setLoading(false);
          return false;
      }
      setLoading(true);
      setOpen(false);
      try{
          let req = {
              token:tokenJwt,
              profile: {
                id: profileId,
                name: profileName,
                description: profileDesc
              }
          } ;
       
        await api.post('api/profileupdate/',req);

        setLoading(false);
        setReload(true);
        errorMsg("Perfil actualizado","success");
      }catch(err){
        setLoading(false);
        errorMsg("Error al actualizar perfil","error");
      }
    }
  return (
     <>
     
     {content()}
    
     <Dialog onClose={() => handleOpen(false)} open={open}>
      <DialogTitle>{profileId ? 'Modificar Perfil' :'Agregar Perfil'}</DialogTitle>
      <DialogContent>
          <DialogContentText>
            Ingresa los datos solicitados para poder generar/modificar un perfil
          </DialogContentText>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre"
              fullWidth
              value={profileName}
              onChange={(e) => setProfileName(e.target.value)}
              variant="standard"
            />
          </Box>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="desc"
              label="Descripción"
              fullWidth
              value={profileDesc}
              onChange={(e) => setProfileDesc(e.target.value)}
              variant="standard"
            />
          </Box>
          {!profileId && (
            <Box sx={{ m: 2 }} >
            <InputLabel id="PermisosLabel">Permisos</InputLabel>
            <Select
                labelId="PermisosLabel"
                id="Permisos"
                fullWidth
                label="Permisos"
                onChange={handleChangeMultiple}
                value={currentPerm}
                multiple
                native
                input={<OutlinedInput label="Permisos" />}
            >
                {permissions.map((prof) => {
                    return( <option key={prof.id} value={prof.id}>{prof.name}</option>)
                    
                })}
                
            </Select>
          </Box>
          )

          }
          
         
        </DialogContent>
        
        <DialogActions>
          <Button onClick={() => handleOpen(false)}>Cancel</Button>
          {profileId? 
          <Button variant="contained" style={{color: theme.palette.primary.contrastText,background: theme.palette.primary.main}} onClick={() => updateProfileAction()}>Guardar</Button>
          :
          <Button variant="contained" style={{color: theme.palette.primary.contrastText,background: theme.palette.primary.main}} onClick={() => addProfile()}>Agregar</Button>
          }
          
        </DialogActions>
    </Dialog>
     </>
  );
}

export default Perfiles;
