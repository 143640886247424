import * as React from 'react';
import { DataGrid, GridColumnHeaderMenu } from '@mui/x-data-grid';
import { DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import { green, grey, red, yellow } from "@material-ui/core/colors";
import DoneIcon from '@mui/icons-material/Done';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link as LinkW } from '@mui/material/';
import { Link } from "react-router-dom";
import DateP from "../DateP";
import PropTypes from 'prop-types';
import { Add, Autorenew, CheckCircle, Clear, Delete, PlusOne, Save, Search } from '@material-ui/icons';
import api from '../../services/api';
import { LoadingButton } from '@mui/lab';
import CustomFilter from '../CustomFilter';
import ImageViewerModal from '../ImageViewer';
import { debounce } from 'lodash';


function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >   {
                props?.membTypes.length > 1 &&
                <TextField
                    select
                    defaultValue={props.allTypes.toString()}
                    variant="outlined"
                    onChange={(e) => props.setCurrentType(e.target.value.split(','))}
                    label="Tipo de membresia"
                    style={{
                        minWidth: '200px'
                    }}

                >
                    <MenuItem key={10} value={props.allTypes.toString()}>
                        Todos
                    </MenuItem>
                    {
                        props?.membTypes?.map((type, index) => {
                            return <MenuItem key={index} value={type.value.toString()}>
                                {type.label}
                            </MenuItem>
                        })
                    }
                </TextField>
            }

            <TextField
                variant="outlined"
                value={props.value}
                onChange={props.onChange}
                placeholder="Buscar"
                InputProps={{
                    startAdornment: <Search fontSize="small" />,
                    endAdornment: (
                        <IconButton
                            title="Clear"
                            aria-label="Clear"
                            size="small"
                            style={{ visibility: props.value ? 'visible' : 'hidden' }}
                            onClick={props.clearSearch}
                        >
                            <Clear fontSize="small" />
                        </IconButton>
                    ),
                }}

            />
        </Box>
    );
}

QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    membTypes: PropTypes.array.isRequired,
    setCurrentType: PropTypes.func.isRequired,
    allTypes: PropTypes.array.isRequired,
};
/**
 * Tipos de pago que no son tarjeta
 * Pasar a BD
 */
const payTypes = [
    'Depósito a la cuenta por agente o banco',
    'Transferencia Bancaria (Desde el mismo banco que maneja la organización)',
    'Transferencia Interbancaria (Desde otro banco que no maneja la organización)',
    'Banco o Agente en Banco de la Nación (Perú)',
    'Pago en tienda (Oxxo, Baloto, entre otros)',
    'Pago por aplicativo (Yape, plin, entre otro)',
    'Efectivo'
]
/**
 * Estatus soporte
 * Pasar a BD
 */
const sopStatus = [
    { 'name': 'Pendiente Soporte', value: 'Pendiente' },
    { 'name': 'Aprobada', value: 'Aprobada' },
    { 'name': 'Desaprobada', value: 'Desaprobada' },
    { 'name': 'Cancelada', value: 'Cancelada' },
]
/**
 * Estatus plataforma
 * Pasar a BD
 */
const platStatus = [
    { 'name': 'Pendiente Plataforma', value: 'Pendiente' },
    { 'name': 'Activada', value: 'Activada' },
    { 'name': 'Desactivada', value: 'Desactivada' },
    { 'name': 'Suspendida', value: 'Suspendida' },
    { 'name': 'Cancelada', value: 'Cancelada' },
]




/**
 * Componente para mostrar la tabla de clientes 
 * @param {*} param0 
 * @returns 
 */
export default function ClientsTableV2({ onFilter, allTypes, membTypes, setLoading, setCurrentType, msg, reload, setReload, title, theme, countByStatus, clients, permissions, isEditable, dateIni, dateEnd, setDateIni, setDateEnd, onEdit, onUpdateMemb, onDelete, onUpload, sendEmail, levelList, systems }) {
    /**
     *  Activa la lisencia de uso para datagrid pro
     */
    LicenseInfo.setLicenseKey(
        'bfc6e20fb3bd4afcc42e60d5cffc5096T1JERVI6NDA0OTQsRVhQSVJZPTE2Nzk4ODAxOTQwMDAsS0VZVkVSU0lPTj0x',
    );
    /**
     * Guarda el valor de las celdas editadas
     */
    const [editRowsModel, setEditRowsModel] = React.useState({});

    /**
     * Guarda el valor de las celdas editadas
     */
    const [selection, setSelection] = React.useState([]);

    /**
    * Guarda los datos filtrados
    */
    const [filteredClients, setFilteredClients] = React.useState(clients);

    /**
    * Guarda los datos filtrados
    */
    const [selectionModelReload, setSelectionModelReload] = React.useState([]);

    /**
     * Cambia el tamaño de las columnas
     */

    const [tempW, setTempW] = React.useState(200);

    /**
     * Texto de búsqueda
     */
    const [searchText, setSearchText] = React.useState('');

    /**
     * Texto de búsqueda
     */
    const [latsFilters, setLatsFilters] = React.useState([]);

    /**
     * Variables para mostrar los certificados del usuario
     */
    const [certificadosUser, setCertificadosUser] = React.useState([]);

    /**
     * Variables para mostrar los cursos del usuario
     */
    const [listaCursos, setListaCursos] = React.useState([]);
    const [listaCursosFull, setListaCursosFull] = React.useState([]);

    const [listaCursosWP, setListaCursosWP] = React.useState([]);
    const [listaCursosWPFull, setListaCursosWPFull] = React.useState([]);

    const [currentUser, setCurrentUser] = React.useState({
        systemName: '',
        user_id: '',
        percent: ''
    });

    const [openCert, setOpenCert] = React.useState(false);
    const [openCursos, setOpenCursos] = React.useState(false);
    const [openAddCursos, setOpenAddCursos] = React.useState(false);

    const [isLoadingCourse, setIsLoadingCourse] = React.useState(false);
    const [isLoadingWpCourse, setIsLoadingWpCourse] = React.useState(false);
    /**
     * Constante que contiene el archivo a subir
     */
    const [file, setFile] = React.useState(null);

    /** Pagination */

    const [pageSize, setPageSize] = React.useState(20);

    /** Comprobantes y archivos */
    const [openComp, setOpenComp] = React.useState(false);
    const [comprobantes, setComprobantes] = React.useState([]);

    /**
     * Variables para el modal de imagenes
     */
    const [openImg, setOpenImg] = React.useState(false);

    /**
     * Variables para el modal de imagenes
     */
    const [currentMember, setCurrentMember] = React.useState({});

    /**
     * Actualiza los clientes cada que se consultan de nuevo
     */
    React.useEffect(() => {
        setFilteredClients(clients);
        // setSearchText('');
        setEditRowsModel({});

    }, [clients])




    const debouncedSearch = React.useCallback(
        debounce((searchTerm) => {
            onFilter(searchTerm);
        }, 1000), // 500 ms de espera
        [] // Dependencias del useCallback. Vacío significa que la función no se recrea
    );
    /**
     * Busca el valor que conincida con lo seleccionado
     * @param {*} searchValue 
     */
    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        debouncedSearch(searchValue)
        // onFilter(searchValue);
        /**const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = clients.filter((row) => {

            return Object.keys(row).some((field) => {
                try {

                    if (typeof (row[field]) === 'object') {


                        return Object.keys(row[field]).some((field2) => {

                            return searchRegex.test(row[field][field2].toString());
                        })

                    } else {
                        return searchRegex.test(row[field].toString());
                    }

                } catch (err) {
                    // console.log(field)
                    return '';
                }

            });
        });


        setFilteredClients(filteredRows);*/
    };

    /**
     * Busca el valor que conincida con lo seleccionado en los filtros
     * @param {*} searchValue 
     */
    const secondFilter = (coumnName, value) => {


        const filterValues = {
            ...latsFilters,
            [coumnName]: value
        }
        let finalResults = null;


        setLatsFilters(filterValues);
        finalResults = clients.filter(function (item) {

            let isInFilter = true;

            for (var key in filterValues) {


                const newKey = key.split('.');


                if (newKey.length > 1) {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[newKey[0]][newKey[1]]) >= 0;

                    } else {
                        if (!item[newKey[0]][newKey[1]]) {

                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[newKey[0]][newKey[1]] === undefined || !item[newKey[0]][newKey[1]].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }


                } else {

                    if (typeof (filterValues[key]) === 'object' && filterValues[key].length > 0) {

                        isInFilter = isInFilter && filterValues[key].indexOf(item[key]) >= 0;

                    } else {

                        if (!item[key]) {
                            isInFilter = isInFilter && filterValues[key] == '';
                            continue;
                        }
                        if (item[key] === undefined || !item[key].toString().toLowerCase().includes(filterValues[key].toString().toLowerCase())) {

                            isInFilter = isInFilter && false;
                        }
                    }
                }


            }
            return isInFilter;
        });

        setFilteredClients(finalResults);
    };
    /**
     * Actualiza los valores editados
     */
    const handleEditRowsModelChange = React.useCallback((model) => {
        setEditRowsModel(model);
    }, []);

    /**
     * Revisa si tiene los permisos necesarios
     * @param {*} permission 
     * @returns 
     */
    const checkPermission = (permission) => {


        return permissions.indexOf(permission) >= 0 && isEditable;
    }

    /**
     * Regresa el estatus con color
     * @param {*} rowData 
     * @returns 
     */
    const showStatus = (status, pendString) => {
        /**
         * Regresa el color rojo si esta pendiente
         */
        if (status === `Pendiente`) {
            return (<Chip label={status + ' ' + pendString} color="error" style={{ background: yellow[400] }} />)
        }
        /**
         * Regresa color verde si esta aprobada
         */
        if (status === "Aprobada" || status === "Activada") {
            return (<Chip label={status} color="success" style={{ background: green[400] }} />)
        }
        /**
         * Regresa el color gris si esta cancelada 
         */
        if (status === "Cancelada" || status === "Suspendida") {
            return (<Chip label={status} color="error" style={{ background: grey[400] }} />)
        }
        /**
         * Regresa el color amarillo 
         */
        if (status === "Desactivada") {
            return (<Chip label={status} color="error" style={{ background: grey[300] }} />)
        }
        /**
         * Regresa el color gris si esta cancelada 
         */
        if (status === "Desaprobada") {
            return (<Chip label={status} color="error" style={{ background: red[300] }} />)
        }
    }
    /**
     * Regresa la url de un sistema
     * @param {*} sysName 
     * @returns 
     */
    const getUrlSystem = (sysName) => {
        for (let i = 0; i < systems.length; i++) {
            if (systems[i].name == sysName) {
                return `${systems[i].url}wp-admin/admin-ajax.php?action=get_cert`;
            }
        }
    }
    /**
     * Vista para la edición de un campo simple
     * @param {*} props 
     * @returns 
     */
    const SimpleEditField = (props) => {

        const { id, field, value, api } = props;

        const [newValue, setNewValue] = React.useState(value)

        /**
         * Si el valor cambia lo asigna para guardarlos posteriormente en el commit
         * @param {*} event 
         */
        const handleChange = (event) => {
            const { target } = event;
            const value = target.value;
            api.setEditCellValue({ id, field, value })
            setNewValue(target.value)
        }

        /**
         * Cuando presiona el botón se guarda el nuevo valor
         */
        const handleCommit = async (event) => {

            api.commitCellChange({ id, field })
            api.setCellMode(id, field, "view");
            event.stopPropagation();


            /**
             * Objeto para enviar la petición de actualización
             */
            const updateObj = {
                systemName: props.row.systemName,
            }

            /**
            * Para el comprobante
            * es necesario como dataForm
            */
            if (props.field == 'ticket') {
                props.row[props.field] = await onUpload(props.row.id, props.row.systemName, props.row.Client.username, file);
                requestSearch(searchText);

                setFile(null);
            }
            /**
            * Para objetos dentro de la información de pago 
            * es necesario enviar el opbjeto order
            */
            else if (props.field.includes('MembershepInfo')) {

                props.row[props.field] = newValue;
                let fields = props.field.split('.');

                props.row[fields[0]][fields[1]] = newValue;

                updateObj.field = {
                    [fields[1]]: newValue
                }

                updateObj.order = {

                    id: props.row.MembershepInfo.id
                }
                await onEdit(updateObj);
            }
            /**
             * Para objetos dentro de la información del cliente 
             * es necesario enviar el objeto client
             */
            else if (props.field.includes('Client')) {

                let fields = props.field.split('.');

                props.row[fields[0]][fields[1]] = newValue;

                updateObj.field = {
                    [fields[1]]: newValue
                }

                updateObj.client = {
                    username: props.row.Client.username,
                    wpId: props.row.Client.wpId
                }
                await onEdit(updateObj);
            }
            /**
             * Para los objetos dentro de la membresía excluyendo el id
             * se actualiza sin problema
             */
            else {

                props.row[props.field] = newValue;

                /**
                 * Si es la membresía, se cambia
                 * sino se envia normal
                 */
                if (props.field == 'memb') {

                    updateObj.field = {
                        'membership': newValue.membership
                    }
                } else {
                    updateObj.field = {
                        [props.field]: newValue
                    }
                }


                updateObj.member = {
                    wpMemberId: props.row.wpMemberId,
                    id: props.row.id
                }

                await onEdit(updateObj);
            }

        };

        /**
         * Para subir el comprobante
         */
        if (props.field == 'ticket') {

            return (
                <Grid container sx={{ width: '100%' }} >
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                        <label htmlFor="certFile">
                            <input
                                id="certFile"
                                type="file"
                                hidden
                                onChange={(e) => { setFile(e.target.files[0]); }}
                            />
                            <LoadingButton
                                variant={"outlined"}
                                autoFocus
                                fullWidth
                                component="span"
                                sx={{ color: theme.palette.secondary.main }}
                                endIcon={<Save />}
                                loadingPosition="end"

                            >
                                {!file ? "Subir comprobante" : file.name}

                            </LoadingButton >
                        </label>

                    </Grid>

                    <Grid item xs={2} sm={2} md={2} lg={2} >
                        <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                            <DoneIcon color="success" />
                        </IconButton>
                    </Grid>
                </Grid>

            )
        }

        if (props.field == 'MembershepInfo.payMethod') {
            return (
                <Grid container sx={{ width: '100%' }} >
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Select
                            labelId="paymethod"
                            id="paymethod"
                            fullWidth
                            label="paymethod"
                            value={value}
                            onChange={handleChange}
                            autoFocus
                            input={<OutlinedInput label="Método de pago" />}
                        >
                            {payTypes.map((payType) => {
                                return (<MenuItem key={payType} value={payType}>{payType}</MenuItem>)

                            })}

                        </Select>

                    </Grid>

                    <Grid item xs={2} sm={2} md={2} lg={2} >
                        <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                            <DoneIcon color="success" />
                        </IconButton>
                    </Grid>
                </Grid>
            )
        }

        /**
         * Si es la membresia la que se edita
         */
        if (props.field == 'memb') {

            return (
                <Grid container sx={{ width: '100%' }} >
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Select
                            labelId="perfilLabel"
                            id="Perfil"
                            fullWidth
                            label="Perfil"
                            value={value.membership}
                            autoFocus
                            onChange={(e) => handleChange({ target: { value: { membership: e.target.value, systemName: value.systemName } } })}
                            input={<OutlinedInput label="Perfil" />}
                        >
                            {levelList[value.systemName].map((level) => {
                                return (<MenuItem key={level.wpIdLevel} value={level.wpIdLevel}>{level.name}</MenuItem>)

                            })}

                        </Select>


                    </Grid>

                    <Grid item xs={2} sm={2} md={2} lg={2} >
                        <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                            <DoneIcon color="success" />
                        </IconButton>
                    </Grid>
                </Grid>

            )
        }

        return (
            <Grid container sx={{ width: '100%' }} >
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        autoFocus
                        variant="outlined"
                        defaultValue={value}
                        onChange={handleChange}
                    />

                </Grid>

                <Grid item xs={2} sm={2} md={2} lg={2} >
                    <IconButton color="success" sx={{ p: '10px' }} aria-label="menu" onClick={handleCommit}>
                        <DoneIcon color="success" />
                    </IconButton>
                </Grid>
            </Grid>
        )

    }

    const obtenerFechaEnFormato = (fechaUTC) => {
        const fechaActual = new Date(fechaUTC);

        const dia = String(fechaActual.getDate()).padStart(2, '0');
        const mes = String(fechaActual.getMonth() + 1).padStart(2, '0');
        const anio = fechaActual.getFullYear();
        const hora = String(fechaActual.getHours()).padStart(2, '0');
        const minutos = String(fechaActual.getMinutes()).padStart(2, '0');

        const fechaFormateada = `${dia}-${mes}-${anio} ${hora}:${minutos}`;

        return fechaFormateada;
    }

    const columns = [

        /** Fecha de registro de la membresia - createdAt*/
        {
            headerName: 'Fecha de registro', field: 'createdAt', width: tempW, editable: false, valueGetter: (params) => {
                return obtenerFechaEnFormato(params.row.createdAt);
            }
        },
        /** Conteo de membresías*/
        {
            headerName: 'Conteo membresías', field: 'memberNum', editable: false, width: 60, valueGetter: (params) => {
                return `${params.row.memberNum} / ${params.row.totalAmount}`;
            }
        },
        /** Nombre del cliente - Client.name*/
        {
            headerName: 'Nombre', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'Client.name', width: 220, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.Client.name;
            }
        },
        /** Usuario con el que entra al wp - Client.username */
        {
            headerName: 'Usuario', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'Client.username', width: 180, editable: false, valueGetter: (params) => {
                return params.row.Client.username;
            }
        },
        /** Contraseña en plano del usuario, dios mio - Client.cleanPassword */
        {
            headerName: 'Password', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'Client.cleanPassword', width: 180, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Editar password') ? true : false, valueGetter: (params) => {
                return params.row.Client.cleanPassword;
            }
        },
        /** Correo registrado - Client.email */
        {
            headerName: 'Correo', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'Client.email', width: 220, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.Client.email;
            }
        },
        /** Campo para abrir whatsapp con el número del cliente en caso de que tenga - Client.phone*/
        {
            headerName: '', field: '', fieldName: 'phone', width: 50, editable: false, renderCell: (params) => {
                return params.row.Client.phone && params.row.Client.phone != '' ?
                    <><LinkW target="_blank" rel="noopener" href={"https://wa.me/" + params.row.Client.phone}><WhatsAppIcon color="success" /></LinkW></> :
                    '';
            }
        },
        /** Campo para abrir whatsapp con el número del cliente en caso de que tenga - Client.phone*/
        {
            headerName: 'Teléfono', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'Client.phone', width: 190, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.Client.phone;
            },
        },
        /** Método de pago - MembershepInfo.payMethod */
        {
            headerName: 'Método de pago', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} value={latsFilters[props.field]} isArray isSelect list={payTypes} field={props.field} filterFunc={secondFilter} />) }, field: 'MembershepInfo.payMethod', width: 220, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.MembershepInfo.payMethod;
            }
        },
        /** País del usuario - Client.country */
        {
            headerName: 'País', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'Client.country', renderEditCell: (props) => <SimpleEditField {...props} />, width: 160, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.Client.country
            }
        },
        /** Dirección registrada de pago - MembershepInfo.address */
        {
            headerName: 'Direccion', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'MembershepInfo.address', width: 220, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.MembershepInfo.address
            }
        },
        /** Banco desde donde pagó el usuario - MembershepInfo.bank*/
        {
            headerName: 'Banco', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'MembershepInfo.bank', width: 220, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.MembershepInfo.bank
            }
        },
        /** Empresa de pago - PENDINENTE */
        {
            headerName: 'Beneficiario', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'MembershepInfo.enterprise', renderEditCell: (props) => <SimpleEditField {...props} />, width: 220, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.MembershepInfo.enterprise
            }
        },
        /** Número de referencia - MembershepInfo.referNum */
        {
            headerName: 'N° Referencia', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'MembershepInfo.referNum', renderEditCell: (props) => <SimpleEditField {...props} />, width: 220, editable: checkPermission('Edita membresia') ? true : false, valueGetter: (params) => {
                return params.row.MembershepInfo.referNum
            }
        },
        /** Fecha de pago del cliente - MembershepInfo.paymentDate */
        {
            headerName: 'Fecha de pago', field: 'MembershepInfo.paymentDate', renderEditCell: (props) => <SimpleEditField {...props} />, width: 180, fieldName: 'paymentDate', editable: false, valueGetter: (params) => {
                return params.row.MembershepInfo.paymentDate
            }
        },
        /** Fecha de termino de la membresía -  endDate*/
        {
            headerName: 'Finalizacion', field: 'endDate', width: 180, editable: false, valueGetter: (params) => {
                return params.row.endDate
            }
        },
        /** Impresión de certificados - PENDIENTE*/
        {
            headerName: 'Ver cursos', field: 'name', width: 200, editable: false, renderCell: (params) => (

                <>
                    <Button
                        color="primary"
                        onClick={async () => {

                            try {
                                let userObj = {
                                    systemName: params.row.systemName,
                                    user_id: params.row.wpClientId,
                                    percent: "0",
                                    systemName: params.row.systemName,
                                    membID: params.row.id,

                                }
                                setIsLoadingCourse(true);
                                setOpenCursos(true);

                                setCurrentUser(userObj);
                                let req = { systemName: params.row.systemName, membID: params.row.id, user_id: params.row.wpClientId, percent: 0 }


                                let { data } = await api.post('api/courses/list', req);

                                setListaCursos(data.courses);
                                setListaCursosFull(data.courses);
                            } catch (err) {
                                console.log(err);
                            }
                            setIsLoadingCourse(false);


                        }}
                    >
                        Ver cursos
                    </Button>
                </>
            )
        },
        /** Ver cursos*/
        {
            headerName: 'Imprimir Certificado', field: 'name2', width: 200, editable: false, renderCell: (params) => (

                <>
                    <Button
                        onClick={async () => {
                            let userObj = {
                                systemName: params.row.systemName,
                                user_id: params.row.wpClientId,
                                percent: "70"
                            }
                            setOpenCert(true);
                            setCurrentUser(userObj);
                            let req = { systemName: params.row.systemName, user_id: params.row.wpClientId, percent: 70 }

                            let cursosTemp = await api.post('api/certList/', req);

                            setCertificadosUser(cursosTemp.data);

                        }}
                    >
                        Ver certificados
                    </Button>
                </>
            )
        },
        /** Tipo de membresia - memb*/
        {
            headerName: 'Membresia', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} list={levelList[props.systemName]} filterFunc={secondFilter} />) }, field: 'memb', width: 170, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, renderCell: (params) => {

                let memb = "Sin membresía";

                /**
                 * Reccore la lista de membresias del sistema
                 * asigna el nombre
                 */
                levelList[params.row.systemName]?.map((level) => {

                    if (params.row.memb.membership == level.wpIdLevel) {
                        memb = level.name;
                    }
                })
                return memb;

            }
        },
        /** Sistema al que pertenece el cliente - systemName*/
        {
            headerName: 'Sistema', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} value={latsFilters[props.field]} isSelect list={systems} keys={['name', 'name']} field={props.field} filterFunc={secondFilter} />) }, field: 'systemName', width: 180, editable: false, filterOnItemSelect: true,/*lookup: systemList,*/renderCell: (params) => {
                return params.row.systemName
            }
        },
        /**  */
        {
            headerName: 'Código COURIER', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'courierCode', width: 180, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, renderCell: (params) => {
                return params.row.courierCode ? params.row.courierCode : 'Sin código'
            }
        },
        /**  */
        {
            headerName: 'Nombre COURIER', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'courierName', width: 220, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, renderCell: (params) => {
                return params.row.courierName ? params.row.courierName : 'Sin nombre'
            }
        },
        /**  */
        {
            headerName: 'Comentario', field: 'comment', renderEditCell: (props) => <SimpleEditField {...props} />, editable: true, width: 200, renderCell: (params) => {
                return params.row.comment ? params.row.comment : 'Sin observaciones'
            }
        },
        /** Usuario que autorizó la membresía -  userAuth*/
        {
            headerName: 'Usuario revisión', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'userRev', width: 220, editable: false, valueGetter: (params) => {
                return params.row.userRev
            }
        },
        /** Usuario que activo la membresía -  userApprove*/
        {
            headerName: 'Usuario autorización', renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: 'userApprove', width: 220, editable: false, valueGetter: (params) => {
                return params.row.userApprove
            }
        },
        /** Estatus de soporte - status */
        {
            headerName: "Soporte", renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} value={latsFilters[props.field]} keys={['value', 'name']} isSelect list={sopStatus} field={props.field} filterFunc={secondFilter} />) }, field: "status", width: 180, editable: false, filterOnItemSelect: true, lookup: { 'Pendiente': 'Pendiente Soporte', 'Aprobada': 'Aprobada' }, renderCell: (params) =>
                showStatus(params.row.status, 'Soporte')
        },
        /** Estatus de plataforma, si esta activa la membresia - status2 */
        {
            headerName: "Plataforma", renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} value={latsFilters[props.field]} keys={['value', 'name',]} isSelect list={platStatus} field={props.field} filterFunc={secondFilter} />) }, field: "status2", width: 180, editable: false, filterOnItemSelect: true, lookup: { 'Pendiente': 'Pendiente Plataforma', 'Aprobada': 'Aprobada' }, renderCell: (params) =>
                showStatus(params.row.status2, 'Plataforma')
        },
        /** Comprobante de pago, campo para agregar comprobante - ticket */
        {
            headerName: "Estatus Comprobante", renderHeader: (props) => { return (<CustomFilter title={props.colDef.headerName} field={props.field} filterFunc={secondFilter} />) }, field: "ticket", width: 250, renderEditCell: (props) => <SimpleEditField {...props} />, editable: checkPermission('Edita membresia') ? true : false, renderCell: (params) => {
                return params.row.ticket ? 'Con comprobante' : params.row?.ClientImages?.length >= 1 ? 'Con comprobante manual' : 'Sin comprobante'
            }
        },
        /** Comprobante de pago, campo para agregar comprobante - ticket */
        {
            headerName: "Comprobante", field: "test", editable: false, width: 150, renderCell: (params) => {
                {/*<Link to={{ pathname: params.row.ticket }} target="_blank" >Revisar</Link>*/ }
                return (
                    
                        <Button
                            variant="outlined"
                            fullWidth
                            //color={"primary"}
                            //sx={{ color: theme.palette.secondary.main}} 
                            onClick={() => {
                                //setOpenComp(true);
                                setCurrentMember(params.row);
                                setOpenImg(true);
                                setComprobantes([params.row.ticket])
                            }}
                        >
                            
                            {params.row.ticket ||  params.row?.ClientImages?.length >= 1 ? 'Revisar': 'Agregar'}
                        </Button>
                        
                )
            }
        },

    ];

    const renderButton = (textButton, permission, status, action = onUpdateMemb, renderBtn = true) => {
        if (!renderBtn) {
            return '';
        }
        if (checkPermission(permission) && selection.length > 0) {
            return (
                <Grid item xs={4} sm={4} md={1} lg={1}>
                    <Button
                        variant="contained"
                        fullWidth
                        //color={"primary"}
                        //sx={{ color: theme.palette.secondary.main}} 
                        onClick={() => action(selection, status)}
                    >
                        {textButton}
                    </Button>
                </Grid>
            )
        } else {
            return '';
        }

    }
    /**
     * Elimina un curso
     * @param {*} course 
     */
    const deleteCourse = async (course) => {
        try {
            let request = {
                systemName: currentUser.systemName,
                wpUserID: course.wpUserID,
                wpCourseID: course.wpCourseID,
                membID: currentUser.membID,
            }

            if (course?.id) {
                request.id = course.id;
            }
            const { data } = await api.post('/api/courses/delete', request);
            alert("Se elimino el curso")
        } catch (err) {
            alert("Ocurrió un error al eliminar el curso")
        }
        return true;
    }

    /**
     * Recupera un curso
     * @param {*} course 
     */
    const recoverCourse = async (course) => {
        try {
            let request = {
                systemName: currentUser.systemName,
                membID: currentUser.membID,
            }

            if (course?.id) {
                request.id = course.id;
            }
            await api.post('/api/courses/recover', request);
            alert("Se recuperó el curso")
        } catch (err) {
            alert("Ocurrió un error al recuperar el curso")
        }
        return true;
    }


    /**
     * Agrega un curso
     * @param {*} course 
     */
    const addCourse = async (courseID, courseName) => {
        try {

            let request = {
                systemName: currentUser.systemName,
                membID: currentUser.membID,
                courseID,
                courseName
            }

            const { data } = await api.post('/api/courses/add', request);
            if (data.status === "Success") {
                alert("Se agrego el curso a la membresia")
            } else if (data.status === "Error") {
                alert(data.msg)
            }

        } catch (err) {
            alert("Ocurrió un error al agregar el curso")
        }
    }

    /**
     * Trae los cursos para un sistema
     * @param {*} course 
     */
    const getSystemCourses = async (systemName) => {
        setListaCursosWP([]);
        setListaCursosWPFull([]);
        setIsLoadingWpCourse(true);

        try {

            const { data } = await api.post('/api/courses/wp', { systemName });
            if (data.status === "Success") {
                setListaCursosWP(data.courses);
                setListaCursosWPFull(data.courses);
            } else if (data.status === "Error") {
                alert(data.msg)
            }



        } catch (err) {
            alert("Ocurrió un error al agregar el curso")
        }
        setIsLoadingWpCourse(false);
    }

    const filterWpCursos = (value) => {

        if (!value) {
            setListaCursosWP(listaCursosWPFull);
        } else {
            const tempCoursesList = [...listaCursosWPFull]
            const filteredCourses = tempCoursesList.filter((course) => course.post_title?.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
            setListaCursosWP(filteredCourses);
        }
    }

    const filterNormalCourses = (value) => {

        if (!value) {
            setListaCursos(listaCursosFull);
        } else {
            const tempCoursesList = [...listaCursosFull]
            const filteredCourses = tempCoursesList.filter((course) => course.name?.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
            setListaCursos(filteredCourses);
        }
    }
    return (
        <div style={{ height: '80vh', width: '100%' }}>

            <Grid container justify="flex-end">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container>
                        <h2>{title}</h2>
                    </Grid>

                </Grid>
                {
                    selection.length == 0 && (
                        <>

                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container justify="flex-end">
                                    <DateP text={"Fecha inicio"} value={dateIni} setValue={(val) => { setDateIni(val) }} />
                                    <DateP text={"Fecha final"} value={dateEnd} setValue={(val) => { setDateEnd(val); }} />
                                </Grid>

                            </Grid>
                        </>

                    )
                }

                {renderButton('Aprobar', 'Revisar_Membresias', { status: 'Aprobada' })}
                {renderButton('Pendiente', 'Revisar_Membresias', { status: 'Pendiente' }, onUpdateMemb, !checkPermission('Activar_Membresias'))}
                {renderButton('Desaprobar', 'Desaprobar_Membresia', { status: 'Desaprobada' })}
                {renderButton('Activar', 'Activar_Membresias', { status2: 'Activada' })}
                {renderButton('Pendiente', 'Activar_Membresias', { status2: 'Pendiente' })}
                {renderButton('Desactivar', 'Desactivar_membresias', { status2: 'Desactivada' })}
                {renderButton('Suspender', 'Suspender_Membresias', { status2: 'Suspendida' })}
                {renderButton('Eliminar', 'Eliminar_Membresias', null, onDelete)}
                {renderButton('Correo', 'Enviar_Correo', null, sendEmail)}

            </Grid>



            <DataGridPro
                components={{ Toolbar: QuickSearchToolbar }}
                checkboxSelection
                disableSelectionOnClick
                rows={filteredClients}
                columns={columns}
                editRowsModel={editRowsModel}
                rowHeight={30}
                pageSize={pageSize}
                pagination
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[20, 50, 100, clients.length]}
                onEditRowsModelChange={handleEditRowsModelChange}
                onSelectionModelChange={(newSelectionModel) => {

                    /**
                     * Guarda la seleccion actual para recargarla en caso de que cambien los valores
                     */
                    setSelectionModelReload(newSelectionModel);
                    /**
                     * Crea un arreglo con todos los objetos seleccionados
                     */
                    const clientsEdit = [];
                    filteredClients.map((value, key) => {
                        if (newSelectionModel.indexOf(value.id) > -1) {
                            clientsEdit.push(value)
                        }
                    })

                    setSelection(clientsEdit);
                }}
                componentsProps={{
                    toolbar: {
                        value: searchText,
                        onChange: (event) => requestSearch(event.target.value),
                        clearSearch: () => requestSearch(''),
                        membTypes: membTypes,
                        allTypes: allTypes,
                        setCurrentType: setCurrentType
                    },
                }}

            />

            {/**
             * Modal de los certificados
             */}

            <Dialog onClose={() => setOpenCert(false)} open={openCert}>
                <DialogTitle>Certificados</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Certificados disponibles del usuario
                    </DialogContentText>

                    {
                        certificadosUser?.map((cert) => (
                            <Box sx={{ m: 2 }} >
                                <Button
                                    variant={"outlined"}
                                    component="label"
                                    fullWidth
                                    color={"secondary"}
                                    endIcon={file ? <CheckCircle /> : ''}
                                    onClick={() => {
                                        let url = getUrlSystem(currentUser.systemName);
                                        url += '&post_id=false';
                                        url += '&course_id=' + cert.course_id;
                                        url += '&user_id=' + currentUser.user_id;

                                        window.open(url, '_blank').focus();
                                    }}
                                >
                                    {cert.wp_post.post_title}

                                </Button>
                            </Box>
                        ))
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCert(false)}>Cerrar</Button>



                </DialogActions>
            </Dialog>

            <Dialog onClose={() => setOpenCursos(false)} open={openCursos}>
                <DialogTitle>Cursos</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cursos del usuario

                        {
                            checkPermission('Agregar cursos') &&
                            <Button variant={"contained"}
                                component="label"
                                fullWidth
                                color={"secondary"}
                                onClick={() => {
                                    setOpenCursos(false);
                                    setOpenAddCursos(true);
                                    const systemName = currentUser.systemName;


                                    getSystemCourses(systemName);




                                }}
                            >+ Agregar curso
                            </Button>
                        }

                    </DialogContentText>

                    <TextField
                        variant="outlined"
                        fullWidth
                        onChange={e => filterNormalCourses(e.target.value)}
                        placeholder="Buscar"


                    />                    {
                        isLoadingCourse &&
                        <Box sx={{ m: 2 }} >
                            <LoadingButton fullWidth variant={"outlined"} loading={isLoadingCourse}>Cargando cursos</LoadingButton >
                        </Box>
                    }
                    {
                        listaCursos.length == 0 && !isLoadingCourse &&
                        <Box sx={{ m: 2 }} >
                            <LoadingButton fullWidth variant={"outlined"} loading={isLoadingCourse}>No se encontraron cursos</LoadingButton >

                        </Box>
                    }
                    {
                        listaCursos?.map((curso) => (
                            <Box sx={{ m: 2 }} >

                                {
                                    curso.status == "enrolled" && checkPermission("Eliminar cursos") ?
                                        <Button
                                            variant={"outlined"}
                                            component="label"
                                            fullWidth
                                            color={"secondary"}
                                            startIcon={
                                                <Delete onClick={async () => {
                                                    await deleteCourse(curso);
                                                    setOpenCursos(false);
                                                }} />
                                            }
                                        >
                                            {curso.name}

                                        </Button>
                                        :
                                        curso.status == "Deleted" && checkPermission("Recuperar cursos") ?
                                            <Button
                                                variant={"outlined"}
                                                component="label"
                                                fullWidth
                                                color={"secondary"}
                                                startIcon={
                                                    <Autorenew onClick={async () => {
                                                        recoverCourse(curso);
                                                        setOpenCursos(false);
                                                    }} />
                                                }
                                            >
                                                {curso.name}

                                            </Button>
                                            :
                                            <Button
                                                variant={"outlined"}
                                                component="label"
                                                fullWidth
                                                color={"secondary"}
                                            >
                                                {curso.name}

                                            </Button>

                                }

                            </Box>
                        ))
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenCursos(false)}>Cerrar</Button>



                </DialogActions>
            </Dialog>

            <Dialog onClose={() => setOpenAddCursos(false)} open={openAddCursos}>
                <DialogTitle>Cursos</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Cursos disponibles en el systema {currentUser.sysName}

                    </DialogContentText>
                    <TextField
                        variant="outlined"
                        fullWidth
                        onChange={e => filterWpCursos(e.target.value)}
                        placeholder="Buscar"


                    />
                    {
                        isLoadingWpCourse &&
                        <Box sx={{ m: 2 }} >
                            Cargando cursos
                            <LoadingButton fullWidth variant={"outlined"} loading={isLoadingWpCourse}>Cargando cursos desde el sistema</LoadingButton >
                        </Box>
                    }

                    {
                        !isLoadingWpCourse && listaCursosWP?.map((curso) => (
                            <Box sx={{ m: 2 }} >

                                <Button
                                    variant={"outlined"}
                                    component="label"
                                    fullWidth
                                    color={"secondary"}
                                    startIcon={
                                        <Add onClick={async () => {
                                            addCourse(curso.ID, curso.post_title);
                                            // setOpenAddCursos(false);
                                        }} />
                                    }
                                >
                                    {curso.post_title}

                                </Button>


                            </Box>
                        ))
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddCursos(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>

            {/**
             * Modal de los comprobantes
             */}

            <Dialog onClose={() => setOpenComp(false)} open={openComp}>
                <DialogTitle>Archivos de membresia</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>

                    {
                        comprobantes?.map((comprobante) => (
                            <Grid container style={{ width: '100%' }}>
                                <Grid item md={12} style={{ width: '100%' }}>
                                    <img style={{ width: '100%' }} src={comprobante} alt="Comprobante" />
                                </Grid>
                            </Grid>
                        ))
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenComp(false)}>Cerrar</Button>



                </DialogActions>
            </Dialog>

            {/**
             * Modal para el visor de las imagenes
             */}

            <Dialog onClose={() => setOpenImg(false)} open={openImg}>
                <DialogTitle>Archivos de membresia</DialogTitle>
                <DialogContent>

                    <ImageViewerModal
                        theme={theme}
                        member={currentMember}
                        setReload={setReload}
                        reload={reload}
                        setOpen={setOpenImg}
                        msg={msg}
                        setLoading={setLoading}
                        permissions={permissions}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenImg(false)}>Cerrar</Button>



                </DialogActions>
            </Dialog>
        </div>

    );
}

