import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Busca los estilos por id para marcar la opción como seleccionada
 * @param {*} name 
 * @param {*} personName 
 * @param {*} theme 
 * @returns 
 */
function getStyles(id, list, theme) {
  return {
    fontWeight: 200
     // list.find( item => item.id === id ) !== null
       // ? theme.typography.fontWeightRegular
       // : theme.typography.fontWeightMedium,
  };
}

export default function MultiSelect({theme,title,list,onChange,initSelect=[]}) {


    /**
     * Componente para guardar las opciones seleccionada
     */
    const [generalList, setGeneralList] = React.useState(initSelect);

    /**
     * Guarda la lista de id seleccionados
     * @param {*} event 
     */
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;

        setGeneralList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

       if(onChange){
        onChange(typeof value === 'string' ? value.split(',') : value);
       }
    };
    /**
     * Regresa el nombre del item para mostrarlos en el chip
     * @param {*} id 
     */
    const getItemName = (id) =>{
        const tempItem = list.find( item => item.id === id );
        return tempItem?.name;
    }

  return (
    <div>
     
        <InputLabel id={title}>{title}</InputLabel>
        <Select
          labelId={title}
          id={title}
          multiple
          value={generalList}
          fullWidth
          onChange={handleChange}
          input={<OutlinedInput id={`chip-chip`} label="Chip" />}
          renderValue={(selected) => {
             
              return(
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={getItemName(value)} />
              ))}
            </Box>
          )}}
          MenuProps={MenuProps}
        >
          {list.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              style={getStyles(item.id, generalList, theme)}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
     
    </div>
  );
}
