import TablaGeneral from "../../components/TablaGeneral";
import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, MenuItem, Paper, Select, TextField, useTheme } from "@material-ui/core";
import { Box } from "@mui/system";

import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";
import api from "../../services/api";


const Permisos = (props) =>{
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies(['token']);
  const { decodedToken, isExpired } = useJwt(cookies?.token);
  const [permissions,setPermissions] = useState([]);
  const [currentPerm,setCurrentPerm] = useState({});
  const [tokenJwt] = useState(cookies.token);

  useEffect(() => {
    async function getUsers(token){
        try{
            let req = {token:token}
            let {data} = await api.post('api/permissionlist',req);
            setPermissions(data);

        }catch(err){
          console.log(err);
        }
    }

    if(tokenJwt){

      getUsers(tokenJwt);
    }
  },[tokenJwt]);

  const handleOpen = (openVal) => {
    setOpen(openVal);
  }

  const content = () => {
  
  
    return(
      <>
        <Box sx={{ m: 2 }}  boxShadow={10}>

            <Paper elevation={20} >
            
                <TablaGeneral 
                        title={"Permisos"}
                        columns= {[
                        { field: 'name', title: 'Permiso' },
                        { field: 'description', title: 'Descripción'},
                    ]}
                    data={permissions}     
                    options={{
                      headerStyle: {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                      },
                    }}  
                />
            </Paper>
            <Box sx={{ m: 2 }}  boxShadow={3}>
              <Grid container justifyContent="flex-end">
                
                <Fab color="primary" aria-label="add" onClick={() => handleOpen(true)}>
                  <AddIcon />
                </Fab>
              </Grid>
            </Box>
        </Box>
      </>
    );
  }
  return (
     <>
     
     {content()}
    
     <Dialog onClose={() => handleOpen(false)} open={open}>
      <DialogTitle>Agregar usuario</DialogTitle>
      <DialogContent>
          <DialogContentText>
            Ingresa los datos solicitados para poder generar/modificar un nuevo permiso
          </DialogContentText>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nombre"
              fullWidth
              variant="standard"
            />
          </Box>
          <Box sx={{ m: 2 }} >

            <TextField
              autoFocus
              margin="dense"
              id="desc"
              label="Descripción"
              fullWidth
              variant="standard"
            />
          </Box>
        
         
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpen(false)}>Cancel</Button>
          <Button variant="contained" style={{color: theme.palette.primary.contrastText,background: theme.palette.primary.main}} onClick={() => handleOpen(false)}>Agregar</Button>
        </DialogActions>
    </Dialog>
     </>
  );
}

export default Permisos;
