import { Avatar, Card, CardHeader, Chip, Grid, Paper } from "@material-ui/core"
import {  blue, green, grey, red, yellow } from "@material-ui/core/colors"

/**
 * Barra de estatus para colocar
 * @param {*} param0 
 */
const StatusBar = ({statusCount}) =>{

    /**
     * Regresa el objeto por color y con su conteo
     * @param {*} status 
     * @param {*} count 
     */
    const getAvatar = (status,count) =>{
        /**
         * Regresa el color rojo si esta pendiente
         */
         if(status.indexOf("Pendiente") >= 0){
            return(
                <Avatar style={{backgroundColor: yellow[400], color: yellow[400], border: '1px solid yellow',borderColor:yellow[400]}} aria-label="recipe">
                
                </Avatar>
            )
        }
        /**
         * Regresa color verde si esta aprobada
         */
        if(status ==="Aprobada" || status ==="Activada"){
            return(
                <Avatar style={{color: green[400], backgroundColor: green[400], border: '1px solid green',borderColor:green[400]}} aria-label="recipe">

                </Avatar>
            )
        }
        /**
         * Regresa el color gris si esta cancelada 
         */
        if(status ==="Cancelada" || status ==="Suspendida"){
            return(
                <Avatar style={{color: grey[400], backgroundColor: grey[400], border: '1px solid grey',borderColor:grey[400]}} aria-label="recipe">

                </Avatar>
            )
        }
        /**
         * Regresa el color amarillo 
         */
        if(status ==="Desactivada"){
            return(
                <Avatar sx={{color: grey[400], backgroundColor: grey[400], border: '1px solid grey',borderColor:grey[400]}} aria-label="recipe">
           
                </Avatar>
            )
        }
        /**
         * Regresa el color gris si esta cancelada 
         */
        if(status ==="Desaprobada"){
            return(
                <Avatar sx={{color:  red[300], backgroundColor: red[300], border: '1px solid red',borderColor:red[300]}} aria-label="recipe">

                </Avatar>
            )
        }

    }

    return(
        <Grid sx={{
          
        }}>
            <Paper elevation={3}>
                <Grid container>
                    {
                        Object.keys(statusCount).map((key)=>{
                            return(
                                
                                    <Grid item xs={2} sm={2} md={2} lg={2}>
                                        <Card sx={{ minWidth: '100%', }}>
                                        <CardHeader
                                             avatar={
                                                getAvatar(key,statusCount[key])
                                            }
                                            title={statusCount[key]}
                                            subheader={key}
                                        />
                                        
                                        </Card>
                                    </Grid>
                                 
                             
                              
                            )
                        })
                    }
                </Grid>
            </Paper>
        </Grid>
    )
}

export default StatusBar;