import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import Save from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState,useEffect } from 'react';
import Papa from "papaparse";

/**
 * Componente para mostrar el modal del cliente y agregar el archivo csv
 */
const ClientCsv = ({open,setOpen,onSave,theme,currentUser}) =>{


    /**
     * Cariable con los usuarios creados por el csv
     */

    const [clients,setClients] = useState([]);
    /**
     * CSV para la creación de usuarios
     */
    const [file,setFile] = useState(null);

    /**
     * Constante para crear el objeto de usuario cuando existe
     */
    const fieldsCsv = ['user_nicename', 'membershep', 'systemName', 'address', 'bank', 'enterprise', 'referNum', 'paymentDate']

    /**
     * Constante para crear el objeto de usuario cuando es nuevo
     */
    const fieldsNewCsv = ['display_name', 'user_nicename', 'user_email', 'password', 'phone', 'country', 'membershep', 'systemName', 'address', 'bank', 'enterprise', 'referNum', 'paymentDate']

    /**
     * Limpia el archivo y los clientes
     */
    useEffect(()=>{

        setFile(null);
        setClients([]);

    },[open])
    /**
     * Genera el objeto de usuarios para dar de alta 
     * @param {*} file 
     */
    const generateJsonFromCsv = (file) =>{
        setFile(file);

        Papa.parse(file, {
            complete: function(results) {
              

                let clientsTemp = [];
                const dataClients =  results.data;

                for(let i = 1; i< dataClients.length-1;i++){
                    
                    /** Arreglo que contiene los campos a asignar*/

                    let fields = fieldsCsv;


                    let isNew = Object.keys(dataClients[i]).length > 8;
                    /**
                     * Si el objeto contiene mas de 8 campos es un usuario nuevo
                     * sino es uno existente
                     */

                    if(isNew){
                        fields = fieldsNewCsv;
                    }

                    /** Contador para tomar el campo del arreglo de campos (fields) */
                    let count = 0;
                    let tempObj = {};

                    for (let key2 in dataClients[i]) {

                        if (dataClients[i].hasOwnProperty(key2)) {
                            tempObj[fields[count]] =  dataClients[i][key2];
                            count++;
                        }
                    }

                    clientsTemp.push(createReqClient(tempObj,isNew));

                }
                setClients(clientsTemp)
                console.log(clientsTemp);
            }}
        )
    
    }

    /**
    * Crea el objeto con los subObjetos correspondientes
    * @param {*} clientObj 
    * @param {*} isNew 
    */
    const createReqClient = (clientObj,isNew) =>{
        const {
            user_nicename,
            user_email,
            display_name,
            password,
            country,
            phone,
            address,
            bank,
            referNum,
            enterprise, //Beneficiario
            paymentDate,
            membershep,
            systemName

        } = clientObj;

        const client = {
            user_nicename: user_nicename,
            user_email: user_email,
            display_name:display_name,
            password:password,
            country:country,
            phone: phone
        }

        const order = {
            address: address,
            bank: bank,
            referNum: referNum,
            enterprise:enterprise,
            paymentDate: paymentDate,
            payment_type: 'Efectivo',
            total: 0,
            timestamp: new Date()
        }

        const newClientData = {
            refer: currentUser,
            systemName: systemName,
            username: user_nicename,
            membershep: membershep,
            order: order
        }
        
        newClientData.isNew = isNew;
        newClientData.newClient = client;
  

        return newClientData;

    }
    return(
        <>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle> Agregar usuarios CSV</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DialogContentText>
                               Descarga los formatos aqui
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <label htmlFor="certFile">
                                    <input
                                        id="certFile"
                                        type="file"
                                        hidden
                                        onChange={(e) => {generateJsonFromCsv(e.target.files[0]);}}
                                    />
                                    <LoadingButton 
                                        variant={ "outlined"}
                                        
                                        fullWidth
                                        component="span"
                                        sx={{ color: theme.palette.secondary.main}}
                                        endIcon={<Save />}
                                        loadingPosition="end"
                            
                                        >
                                        {!file ? "Subir csv" : file.name}
                                    
                                    </LoadingButton >
                                </label>
                        </Grid>
                    </Grid>
                   
                 
                    
                </DialogContent>
                <DialogActions>
                    <Button theme={theme} variant="outlined" color="error" onClick={() => setOpen(false)}>
                        Cancelar
                    </Button>
                    {
                        clients.length > 0 && (
                           
                            <Button theme={theme} variant="contained" color="primary"  onClick={() => onSave(clients)}>
                                Agregar
                            </Button>

                        )
                    }
                    
                    
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ClientCsv;