
import SearchIcon from '@mui/icons-material/Search';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {   CardActionArea, CardActions, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';


const NotFound = ({showReg = true, msg= "No se encontraron resultados"}) => {
    return(
        <>
            <Card sx={{ display: 'flex' }}>
                <CardActionArea>
                    <CardContent>
                    <Box m={1} display="flex" alignItems="center" flexDirection="column">
                        <Box mb={2}>
                            <SearchIcon  style={{ fontSize: 200 }}/>
                        </Box>
                        <Box  mb={2}>
                            <Typography variant="h5" color="text.secondary">
                            {msg}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    typography: 'body1',
                                    '& > :not(style) + :not(style)': {
                                    ml: 2,
                                    },
                                }}
                              
                                >{showReg && (
                                    <Typography variant="h6" color="text.secondary">
                                        <Link href="#">Regresar</Link>
                                     </Typography>)}
                                </Box>
                           
        
                        </Box>
                    </Box>
                    

                    </CardContent>
                  
                </CardActionArea>
                <CardActions>
                  
                </CardActions>
            </Card>
        </>
    )
}

export default NotFound;