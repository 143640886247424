import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import {  useEffect, useState } from "react";
import { Checkbox, Dialog, DialogTitle, FormControlLabel, Grid, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import Save from '@mui/icons-material/Save';


export default function ClientsModal({title,open,setOpen,onSave,theme,systemList,levelList}) {


    /**
     * Variable para alta de membresía a usuario existente
     * Si es false el usuario existe 
     * Si es true creará un nuevo usuario
     */

    const [isNew, setIsNew] = useState(false);

     /**
     * Variable para el alta de una orden
     * La orden incluye la información de pago
     */

    const [addOrder, setAddOrder] = useState(false);

    /**
     * Sistema en el que se dará de alta
     */
    const [systemName,setSystemName] = useState('');

    /**
     * Comprobante de pago
     */
    const [file,setFile] = useState(null);

    /**
     * Variable para el nuevo cliente
     */
    const [client, setClient] = useState({
        user_nicename:'',
        user_email: '',
        display_name: '',
        password: '',
        phone: '',
        country: ''
    });

    /**
     * Variable para la nueva membresía
     */
    const [member, setMember] = useState({
        membershep: ''
    });

    /**
     * Variable para la nueva información de pago
     */
    const [order, setOrder] = useState({
        address:'',
        payment_type: 'Efectivo',
        total: 0,
        status: 'success',
        bank: '',
        referNum: '',
        enterprise: '',
        timestamp: new Date()
    });

    /**
     * Limpia todos los objetos al volver a abrir el modal
     */
    useEffect(()=>{

        setSystemName('');
        setFile(null);

        setClient({
            user_nicename:'',
            user_email: '',
            display_name: '',
            password: '',
            phone: '',
            country: ''
        });

        setMember({
            membershep: ''
        });

        setOrder({
            address:'',
            payment_type: 'Efectivo',
            total: 0,
            status: 'success',
            bank: '',
            referNum: '',
            enterprise: '',
            timestamp: new Date()
        });

    },[open]);
    

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto client
     * @param {*} e 
     */
    const handleChangeClient= (e) => {
        const event = e.target;

        setClient({
            ...client,
            [event.name]: event.value,
        })

    }

    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto member
     * @param {*} e 
     */
     const handleChangeMember= (valuee) => {

        setMember({
            membershep:valuee
        })

    }

    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto order
     * @param {*} e 
     */
     const handleChangeOrder= (e) => {
        const event = e.target;

        setOrder({
            ...order,
            [event.name]: event.value,
        })

    }
  
    return (
    <>

        <Dialog
            disableEnforceFocus 
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
            {title}
        </DialogTitle >

        <DialogContent dividers>
            <Grid container spacing={2}>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isNew}
                                onChange={(event) => setIsNew(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Nuevo usuario"
                    />
                    
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addOrder}
                                onChange={(event) => setAddOrder(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Incluir información de pago"
                    />
                   
                </Grid>
            {
                /**
                 * Si el usuario es nuevo pide y valida los datos de usuario
                 */
                 isNew ? (
                     <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={client.display_name}
                                label="Nombre" 
                                name="display_name"
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={client.user_nicename}
                                label="Usuario" 
                                name="user_nicename"
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />

                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <TextField 
                                fullWidth 
                                value={client.phone}
                                label="Teléfono" 
                                type="number"
                                name="phone"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <TextField 
                                fullWidth 
                                value={client.country}
                                label="País" 
                                name="country"
                               
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />

                        </Grid>
                       

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={client.user_email}
                                label="Correo" 
                                name="user_email"
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={client.password}
                                label="Contraseña" 
                                name="password"
                                type="password"
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />

                        </Grid>
                    </>
                 ) 
                 :
                 (
                     <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={client.user_nicename}
                                label="Usuario" 
                                name="user_nicename"
                                variant="outlined" 
                                onChange={handleChangeClient}
                            />
                        </Grid>
                        
                     </>
                 )
            }

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Select
                            labelId="Sistema"
                            id="Sistema"
                            fullWidth
                            name="systemName"
                            label="Sistema"
                            value={systemName}
                            onChange={(e) => setSystemName(e.target.value)}
                            input={<OutlinedInput label="Sistema" />}
                    >
                        {systemList?.map((systemN) => {
                            return( <MenuItem key={systemN} value={systemN}>{systemN}</MenuItem>)
                            
                        })}
                            
                    </Select>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Select
                            labelId="membershep"
                            id="Membresía"
                            fullWidth
                            name="membershep"
                            label="Membresía"
                            value={member.membershep}
                            onChange={(e) => handleChangeMember(e.target.value)}
                            input={<OutlinedInput label="Perfil" />}
                    >
                        {levelList[systemName]?.map((level) => {
                            return( <MenuItem key={level.wpIdLevel} value={level.name}>{level.name}</MenuItem>)
                            
                            })}
                            
                    </Select>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label htmlFor="certFile">
                            <input
                                id="certFile"
                                type="file"
                                hidden
                                onChange={(e) => {setFile(e.target.files[0]);}}
                            />
                            <LoadingButton 
                                variant={ "outlined"}
                                
                                fullWidth
                                component="span"
                                sx={{ color: theme.palette.secondary.main}}
                                endIcon={<Save />}
                                loadingPosition="end"
                    
                                >
                                {!file ? "Subir comprobante" : file.name}
                            
                            </LoadingButton >
                        </label>
                </Grid>

                {
                /**
                 * Si se requiere información de pago
                 */
                 addOrder && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={order.enterprise}
                                label="Beneficiario" 
                                name="enterprise"
                                variant="outlined" 
                                onChange={handleChangeOrder}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField 
                                fullWidth 
                                value={order.address}
                                label="Dirección" 
                                name="address"
                                variant="outlined" 
                                onChange={handleChangeOrder}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <TextField 
                                fullWidth 
                                value={order.referNum}
                                label="Núm. Referencia" 
                                name="referNum"
                                variant="outlined" 
                                onChange={handleChangeOrder}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <TextField 
                                fullWidth 
                                value={order.bank}
                                label="Banco" 
                                name="bank"
                                variant="outlined" 
                                onChange={handleChangeOrder}
                            />
                        </Grid>
                    </>
                 )
                }
            
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                Cancelar
            </Button>
            <Button theme={theme} variant="contained" color="primary"  onClick={() => onSave(client,member,order, isNew,addOrder,systemName,file)}>
                Guardar
            </Button>
        </DialogActions>
        </Dialog>
    </>
    );
}