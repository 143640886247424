import { AppBar, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from "@material-ui/core";
import { useEffect,useState } from "react";
import propiedades from '../../ressources/config.json';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";

import Icon from '@mui/material/Icon';
import { useTheme } from '@material-ui/core';
import { Box } from "@mui/system";

const BarComponent = (props) => {
    const drawerWidth = 0;
  
    const [open, setOpen] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['token']);
    const { decodedToken, isExpired } = useJwt(cookies?.token);
    const [profile, setProfile] = useState([]);

    const theme = useTheme();
    useEffect(() => {
        setOpen(false);
        console.log(theme.palette.primary);
    },[]);

    useEffect(() => {
   
        if(decodedToken){
          let {userInfo} = decodedToken;
          setProfile(userInfo.roles);
        }else{
            props.history.push('/login');
        }
    },[decodedToken]);
    
 
     const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };
    
      const handleNavigate = (path) => {
          props.history.push(path);
          setOpen(false);
      }

      const checkPermission = (permissions) => {

          for(let index2  in permissions){
      
              if(profile.indexOf(permissions[index2])>= 0){
                return true;
              }
              
          }
       return false;
      }
      
    return(
       <>
         <AppBar position="static">
           <Toolbar>
           <Box display='flex' flexGrow={1} alignItems="center">
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
                
              </IconButton>

            
              
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Gestión de membresias
              </Typography>
          </Box>
            
             
           </Toolbar>
         </AppBar>
        <div>
                <Drawer
                
                open={open}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                >
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon /> 
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                       Membresias
                    </Typography>
                    </IconButton>
                   
                <List>
                    {propiedades.menuTree.map((propMenu)=>{
                      let cont = 0;
                        if(checkPermission(propMenu.permission)){
                          cont++;
                            return  (
                                <>
                                <ListItem button key={cont} onClick={() => handleNavigate(propMenu.path)}>
                                                        
                                    <ListItemIcon>
                                        
                                        <Icon sx={{ color: theme.palette.secondary.main }}>{propMenu.icono}</Icon>
                                        
                                    </ListItemIcon>
                                    <ListItemText primary={propMenu.title} />
                                </ListItem>
                                <Divider />
                                </>
                            )
                        }
                      
                    })}
                     <ListItem button key={0} onClick={() => {
                         removeCookie('token');
                         props.history.push('/login');
                     }} >
                                                        
                        <ListItemIcon>
                            <Icon sx={{ color: theme.palette.secondary.main }}>exit_to_app</Icon>;
                        
                        </ListItemIcon>
                        <ListItemText primary={"Salir"} />
                    </ListItem>
                    <Divider />
               
                </List>
               
               
                </Drawer>
        </div>
     </>
    ) 
}

export default BarComponent;