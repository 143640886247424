import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

/**
 * Compionente para consutlar la fecha 
 * Dentro de las tablas
 * @param {*} param0 
 * @returns 
 */
export default function DateP({text,value,setValue}) {
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
      
        label={text}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
        inputFormat="dd/MM/yyyy"
      />
    </LocalizationProvider>
  );
}