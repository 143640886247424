import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import api from '../../services/api';
import { useCookies } from 'react-cookie';
import Loading from '../../components/Loading';
import { SnackbarProvider } from 'notistack';
import { useTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';

import Cookies from 'universal-cookie';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" >
      {'Copyright © '}
      <Link color="inherit" href="">
        CCE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function Login(props) {
   
  const [cookies,setCookie] = useCookies(['token']);
  const [loading,setLoading] = React.useState(false);
  const msg = React.useRef();
  const theme = props.theme;
  const theme2 = useTheme();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    setLoading(true);

    if(!data.get('email')){
      msg.current.enqueueSnackbar("El correo es requerido", {
        variant: "warning",
      });
      setLoading(false);
      return false;
    }

    if(!data.get('password')){
      msg.current.enqueueSnackbar("La contraseña no peude estar vacia", {
        variant: "warning",
      });
      setLoading(false);
      return false;
    }

    let request = {
      email: data.get('email'),
      password: data.get('password'),
    };

    try{
       
        let {data} = await api.post("/login",request);
        setCookie('token', data.token, { path: '/' });
        const cookies = new Cookies();
        cookies.set('token2', data.token, { path: '/' });
        props.history.push('/principal');
        setLoading(false);
        
    }catch(error){
       console.log(error);
       msg.current.enqueueSnackbar("Usuario o contraseña incorrectos", {
        variant: "error",
      });
       setLoading(false);
    }
  };



  return (
      <>
      <SnackbarProvider ref={msg} maxSnack={7} />
       
     
      <Loading open={loading}/>
      <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
       
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
           // backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: theme.palette.secondary.main }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Iniciar sesión
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
        
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Iniciar sesión
              </Button>
             
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      </ThemeProvider>
  </>
  );
}