import { Grid } from "@material-ui/core";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { SnackbarProvider } from "notistack";
import { useEffect, useRef, useState } from "react";
import ClientsTable from "../../components/ClientsTable";
import ClientsModal from "../../components/ClientsModal"
import api from "../../services/api";
import Loading from "../../components/Loading";
import EmailModal from "../../components/EmailModal";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import ClientCsv from "../../components/ClientCsv";
import ClientsTableV2 from "../../components/ClientsTableV2";
import StatusBar from "../../components/StatusBar";
import PaymentsTable from "../../components/PaymentsTable";



/**
 * Componente para mostrar todos los registros de membresias 
 * Para cada uno de los sistemas
 */
const Clientes = ({ systemList, currentUser, theme, permissions, countries }) => {

    /**
     * Constante que guarda el objeto de consulta de clientes
     */
    const [clients, setClients] = useState([]);

    /**
    * Constante que guarda el objeto de consulta de clientes
    */
    const [creditClients, setCreditClients] = useState([]);

    /**
     * Constante con las membresias de cada sistema
     */
    const [levelList, setLevelList] = useState({});

    /**
     * Lista de sistemas
     */
    const [systemListData, setSystemListData] = useState({});

    /**
     * Mensajes para el usuario
     */
    const msg = useRef();

    /**
     * Variable para el modal
     */
    const [open, setOpen] = useState(false);

    /**
     * Variable para el modal ed csv
     */
    const [openCsv, setOpenCsv] = useState(false);

    /**
     * Variable para recargar
     */
    const [reload, setReload] = useState(false);

    /**
     * Variable para mostrar pantalla de carga
     */
    const [loading, setLoading] = useState(true);

    /**
     * Variables para el envio de email
     */
    const [openEmail, setOpenEmail] = useState(false);
    const [emailList, setEmailList] = useState([]);
    const [toList, setToList] = useState([]);

    /**
     * Fechas para la consulta de los clientes
     */
    const [dateIni, setDateIni] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const [dateEnd, setDateEnd] = useState(new Date());

    /**
     * Variable para mostrar la vista de credito o efectivo
     */
    const [showCredit, setShowCredit] = useState(false);

    /**
     * Variable para guardar el conteo de estatus
     */
    const [statusCount, setStatusCount] = useState({});

    /**
     * Variable para guardar el conteo de estatus de pagos con credito
     */
    const [statusCreditCount, setStatusCreditCount] = useState({});
    /**
     * Cambia el tipo de membresia
     */
    const [currentType, setCurrentType] = useState(null);
    /**
     * Cambia el titulo de la tabla
     */
    const [title,setTitle] = useState('');

    /**
    * Botones de la barra de opciones
    */

    const actions = [
        {
            icon: <PersonAddAlt1Icon />,
            name: 'Agregar registro',
            permission: 'Agrega Registro',
            click: () => setOpen(true)
        },
        {
            icon: <GroupAddIcon />,
            name: 'Subir registro csv',
            permission: 'Agrega Registro',
            click: () => setOpenCsv(true)
        },
        {
            icon: <CreditCardIcon />,
            name: 'Ver registros de pago con tarjeta',
            permission: 'Ver_Membresias',
            click: () => setShowCredit(true)
        },
        {
            icon: <LocalAtmIcon />,
            name: 'Ver registros de pago en efectivo',
            permission: 'Ver_Membresias',
            click: () => setShowCredit(false)
        },

    ];
    /**
     * Valida los permisos 
     * @param {*} perm 
     */
    const validaPermisos = (perm) => {

        // console.log(permissions.indexOf(perm) != -1);
        // console.log(permissions.indexOf(perm))
        return permissions.indexOf(perm) != -1;
    }
    /**
     * Se ejecuta cuando carga el componente
     */
    useEffect(() => {

        /**
         * Consulta las membresias
         */
        async function getMembs() {
            try {
                /** Consulta las membresias acorde a los sistemas asignados */
                const membsList = await api.post('api/local/level', { systemName: systemList });

                if (membsList.data.status == 'Success') {
                    setLevelList(membsList.data.membs);

                } else {

                }
            } catch (err) {
                console.log(err);
            }
        }

        /**
         * Consulta los sistemas
         */

        async function getSystems() {
            try {
                /** Consulta los sistemas acorde a los sistemas asignados */

                const { data } = await api.post('system/list', { systemName: systemList });

                if (data.status == 'Success') {
                    setSystemListData(data.systems);

                } else {

                }
            } catch (err) {
                console.log(err);
            }
        }

        async function getEmails() {
            try {

                let {data} = await api.post('api/listMails/', { system: systemList });
                
               
                 console.log(data);
                setEmailList(data.emails);

            } catch (err) {
                console.log(err);
                msg?.current?.enqueueSnackbar("Ocurrio un error al consultar los correos del sistema", {
                    variant: "error",
                });
            }
        }

        getMembs();
        getSystems();
        getEmails();

    }, [reload])

    /**
     * Recarga los clientes al cambiar las fechas
     */
    useEffect(() => {

        /**
         * Consulta los clientes
         */
        async function getClients() {
            try {

                /** Consulta los clientes acorde a los sistemas asignados */
                const clientsConsult = await api.post('/api/client/listlocal',
                    {
                        systemName: systemList,
                        permissions: permissions,
                        dateIni: dateIni,
                        dateEnd: dateEnd,
                        countries: countries,
                        filter: {
                            payMethod: 'Efectivo'
                        },
                        type: [...currentType]
                    });
                //console.log(clientsConsult);

                if (clientsConsult.data.status == 'Success') {
                    setClients(clientsConsult.data.membersheps);
                    countByStatus(clientsConsult.data.membersheps)
                } else {
                    msg.current.enqueueSnackbar("Ocurrio un error al consultar los usuarios", {
                        variant: "error",
                    });
                }
            } catch (err) {
                msg.current.enqueueSnackbar("Ocurrio un error al consultar los usuarios", {
                    variant: "error",
                });
            }
            setLoading(false)
        }

        /**
        * Consulta los clientes de pago con tarjeta o paypal
        */
        async function getCreditClients() {
            try {
                /** Consulta los clientes acorde a los sistemas asignados */
                const clientsConsult = await api.post('/api/payments/list',
                    {
                        systemName: systemList,
                        permissions: permissions,
                        dateIni: dateIni,
                        dateEnd: dateEnd,
                        countries: countries,
                        filter: {
                            payMethod: { 'not': 'Efectivo' }
                        },
                        type: [...currentType]
                    });

                if (clientsConsult.data.status == 'Success') {
                    setCreditClients(clientsConsult.data.payments);
                   // countByStatus(clientsConsult.data.membersheps, true)
                } else {
                    msg.current.enqueueSnackbar("Ocurrio un error al consultar los usuarios", {
                        variant: "error",
                    });
                }
            } catch (err) {
                msg.current.enqueueSnackbar("Ocurrio un error al consultar los usuarios", {
                    variant: "error",
                });
            }
            setLoading(false)
        }
        setLoading(true)
        if(currentType){
            setTitle(getTilte(currentType))
            getClients();
            getCreditClients();
        }else{
            setLoading(false)
        }
     

    }, [dateEnd, dateIni, reload, currentType])

    /**
         * Consulta los clientes
         */
    async function getFilteredClients(filterValue) {
        try {

            /** Consulta los clientes acorde a los sistemas asignados */
            const clientsConsult = await api.post('/api/client/listlocal',
                {
                    systemName: systemList,
                    permissions: permissions,
                    dateIni: dateIni,
                    dateEnd: dateEnd,
                    countries: countries,
                    filter: {
                        payMethod: 'Efectivo',
                        text: filterValue
                    },
                    type: [...currentType]
                });
            //console.log(clientsConsult);

            if (clientsConsult.data.status == 'Success') {
                setClients(clientsConsult.data.membersheps);
                countByStatus(clientsConsult.data.membersheps)
            } else {
                msg.current.enqueueSnackbar("Ocurrio un error al consultar los usuarios", {
                    variant: "error",
                });
            }
        } catch (err) {
            msg.current.enqueueSnackbar("Ocurrio un error al consultar los usuarios", {
                variant: "error",
            });
        }
        setLoading(false)
    }
    /**
     * Contabiliza cuantas memrbesías hay de con cada estatus
     * @param {*} clientsCount 
     */
    const countByStatus = (clientsCount, isCredit) => {

        let counterStatus = {};

        clientsCount.map((client, key) => {

            let status = client.status;
            let status2 = client.status2;
            if (status == 'Pendiente') {
                status = 'Pendiente Soporte';
            }
            if (status2 == 'Pendiente') {
                status2 = 'Pendiente Plataforma';
            }
            if (!counterStatus[status]) {
                counterStatus[status] = 1;
            } else {
                counterStatus[status]++;
            }
            if (!counterStatus[status2]) {
                counterStatus[status2] = 1;
            } else {
                counterStatus[status2]++;
            }

        })

        if (isCredit) {
            setStatusCreditCount(counterStatus);
        } else {
            setStatusCount(counterStatus);
        }
    }

    /**
     * Funcion para editar lso campos de la tabla en db
     * @param {*} updateObj 
     */
    const editValue = async (updateObj) => {
        try {
            setLoading(true)
            const { data } = await api.put('api/client/updateclient', updateObj);

            if (data.status == 'Success') {

                msg.current.enqueueSnackbar("Datos actualizados", {
                    variant: "success",
                });

            } else {
                msg.current.enqueueSnackbar("Ocurrio un error al editar el campo", {
                    variant: "error",
                });
            }
        } catch (err) {
            console.log(err);
            msg.current.enqueueSnackbar("Ocurrio un error al editar el campo", {
                variant: "error",
            });
        }
        setLoading(false)
    }
    /**
     * Sube el archivo a google cloud storage
     * lo relaciona con la membresía
     * @param {*} id 
     * @param {*} file 
     */
    const onUploadFile = async (id, systemName, username, file) => {
        /** Se crea el Formdata para enviar el archivo */
        const formData = new FormData();

        formData.append('file', file);
        formData.append('id', id);
        formData.append('systemName', systemName);
        formData.append('username', username);
        try {
            setLoading(true)
            /** Crea los headers para enviar el archivo */
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            }

            const { data } = await api.patch('api/upload/ticket', formData, config);


            if (data.status == 'Success') {

                msg.current.enqueueSnackbar("Comporbante actualizado", {
                    variant: "success",
                });
                setLoading(false)
                return data.url;
            } else {
                msg.current.enqueueSnackbar("Ocurrio un error al actualizar el copmprobante", {
                    variant: "error",
                });
                setLoading(false)
                return '';
            }
        } catch (err) {
            console.log(err);
            msg.current.enqueueSnackbar("Ocurrio un error al actualizar el copmprobante", {
                variant: "error",
            });
            setLoading(false)
            return '';
        }

    }
    /**
     * Agrega una nueva membresía
     * Si es nuevo usuario isNew es true
     * Si incluye información de pago addOrder es true
     * @param {*} client 
     * @param {*} member 
     * @param {*} order 
     * @param {*} isNew 
     * @param {*} addOrder 
     * @param {*} systemName 
     */
    const addMemb = async (client, member, order, isNew, addOrder, systemName, file, validateFile = true) => {


        if (!validateClient(client, isNew)) {
            return false;
        }

        if (!systemName) {
            msg.current.enqueueSnackbar(`Debes elegir un sistema`, {
                variant: "warning",
            });
            return false;
        }

        if (!member.membershep) {
            msg.current.enqueueSnackbar(`Debes elegir una membresía`, {
                variant: "warning",
            });
            return false;
        }

        if (!file && validateFile) {
            msg.current.enqueueSnackbar(`Debes adjuntar un comprobante`, {
                variant: "warning",
            });
            return false;
        }

        if (addOrder) {
            if (!validateOrder(order)) {
                return false;
            }
        }
        /**
         * Objeto para enviar la alta de la membresia
         */
        setLoading(true)
        setOpen(false)
        const formData = new FormData();

        formData.append('file', file);
        formData.append('refer', currentUser);
        formData.append('systemName', systemName);
        formData.append('username', client.user_nicename);
        formData.append('membershep', member.membershep);


        /*let objSend = {
            refer: currentUser,
            username: client.user_nicename,
            systemName: systemName,
            membershep: member.membershep
        } */

        /**
         * Si el cliente es nuevo se agrega al objeto los datos del cliente
         * Se darán de alta local y en el wp
         */
        if (isNew) {
            //objSend.newClient = client;
            formData.append('newClient', client);
        }

        /**
         * Si se agrega información de pago
         * Envia los datos para alta local
         */


        if (addOrder) {
            // objSend.order = order;

            formData.append('order', order);
        }


        try {
            /** Crea los headers para enviar el archivo */
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            }
            const { data } = await api.patch('api/client/addclient', formData, config);

            // console.log(data);

            if (data.status == 'Success') {
                msg.current.enqueueSnackbar(`Membresía agregada con éxito`, {
                    variant: "success",
                });
            } else {
                msg.current.enqueueSnackbar(`Ocurrio un error al agregar membresía`, {
                    variant: "error",
                });
            }

        } catch (err) {
            console.log(err);
            msg.current.enqueueSnackbar(`Ocurrio un error al inesperado`, {
                variant: "error",
            });
        }

        setReload(!reload)

    }

    /**
     * Valida el mínimo de anchura
     * 
     * @param {*} value 
     * @param {*} num 
     */
    const simpleValidation = (name, value, num) => {

        if (!value) {
            msg.current.enqueueSnackbar(`${name} no puede estar vacio`, {
                variant: "warning",
            });
            return false;
        }
        if (value.length < num) {
            msg.current.enqueueSnackbar(`${name} no puede ser menor a ${num} digitos`, {
                variant: "warning",
            });
            return false;
        }

        return true;
    }
    /**
     * Toma el objeto de client para validar todos los campos
     * @param {*} client 
     */
    const validateClient = (client, isNew) => {

        const {
            user_nicename,
            user_email,
            display_name,
            password,
            country,
            phone,
        } = client;

        if (!simpleValidation('usuario', user_nicename.trim(), 5)) {
            return false;
        }

        /**
         * Si el usuario no es nuevo 
         * Ya no se valida lo demás
         */
        if (!isNew) {
            return true;
        }
        if (!simpleValidation('El nombre', display_name.trim(), 5)) {
            return false;
        }
        if (!simpleValidation('El teléfono', phone.trim(), 8)) {
            return false;
        }
        if (!simpleValidation('El país', country.trim(), 3)) {
            return false;
        }
        if (!simpleValidation('El correo', user_email.trim(), 5)) {
            return false;
        }
        if (!simpleValidation('La contraseña', password.trim(), 5)) {
            return false;
        }
        return true;

    }

    /**
     * Valida los datos para agregar un pago
     * @param {*} order 
     * @returns 
     */
    const validateOrder = (order) => {

        const {
            address,
            bank,
            referNum,
            enterprise, //Beneficiario

        } = order;

        if (!simpleValidation('El beneficiario', enterprise.trim(), 5)) {
            return false;
        }
        if (!simpleValidation('La dirección', address.trim(), 3)) {
            return false;
        }
        if (!simpleValidation('El número de referencia', referNum.trim(), 3)) {
            return false;
        }
        if (!simpleValidation('El banco', bank.trim(), 3)) {
            return false;
        }

        return true;
    }

    /**
     * Actualiza la membresia:
     * Aprueba la revisión
     * Activa la membresía (también en wp)
     * Suspende la membresía
     * Cancela la membresía
     * @param {*} statusObj 
     */
    const onUpdateMemb = async (member, statusObj) => {

        /**
         * Pedimos el nombre de quién aprueba
         */
        let userAuth = window.prompt("Es necesario ingresar tu nombre completo para actualizar", "");

        if (userAuth == null || userAuth == "") {
            return false;
        }
        /**
         * Esperamos a que temrinen todos los usuarios
         */
        setLoading(true);
        await Promise.all(member.map(async (memb, key) => {
          
            if (memb.status2 == 'Desactivada' || memb.status2 == 'Pendiente' || memb.status2 == 'Activada') {
                statusObj.oldStatus2 = memb.status2;
            }

            const newMembObj = {
                ...statusObj,
                systemName: memb.systemName,
                membershep: memb.memb.membership,
                memberId: memb.id,
                wpMemberId: memb.wpMemberId,
                userAuth: userAuth
            }

            //console.log(newMembObj);
            try {
                /**Envia la petición de actualización */
                const { data } = await api.put('api/client/updatememb', newMembObj);

                if (data.status == 'Success') {
                    msg.current.enqueueSnackbar(`Datos actualizados ${memb.Client.username}`, {
                        variant: "success"
                    });

                } else {
                    msg.current.enqueueSnackbar(`Error al actualizar usuario ${memb.Client.username}`, {
                        variant: "error",
                    });
                }
            } catch (err) {
                msg.current.enqueueSnackbar(`Error al actualizar usuario ${memb.Client.username}`, {
                    variant: "error",
                });
            }

        }))
        setReload(!reload)
    }
    /**
     * Elimina una membresía
     * @param {*} clientData 
     */
    const deleteMemb = async (clientData) => {

        setLoading(true);
        await Promise.all(clientData.map(async (memb, key) => {
            try {
                const objPetition = {
                    systemName: memb.systemName,
                    memberId: memb.id,
                    wpMemberId: memb.wpMemberId,
                    username: currentUser
                }
                const { data } = await api.put('api/member/delete', objPetition);

                if (data.status == 'Success') {
                    msg.current.enqueueSnackbar(`Membresía eliminada`, {
                        variant: "success"
                    });

                } else {
                    msg.current.enqueueSnackbar(`Error al eliminar membresía`, {
                        variant: "error",
                    });
                }
            } catch (err) {
                msg.current.enqueueSnackbar(`Error al eliminar membresía`, {
                    variant: "error",
                });
            }
        }))
        setReload(!reload)

    }

    /**
     * Crea la lista de destinatarios para el envio de emails
     */
    const setUsersTo = (usersSelected) => {

        let userMails = [];
        for (let i = 0; i < usersSelected.length; i++) {

            // console.log(usersSelected[i])
            userMails.push(usersSelected[i].Client.email);
        }
        setToList(userMails);
        setOpenEmail(true);
    }

    /**
     * Envia el email a los usuarios seleccionados
     * @param {*} data 
     * @param {*} fileE 
     */
    const sendEmail = async (data, fileE) => {
        const formData = new FormData();

        formData.append('file', fileE)
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        }

        formData.append('toEmail', toList)
        formData.append('email', data.email)
        formData.append('system', data.system)
        formData.append('subject', data.subject)
        formData.append('msg', data.msg)
        setOpenEmail(false);
        setLoading(true);

        try {

            await api.patch('api/sendMail/', formData, config);
            msg.current.enqueueSnackbar("Correo enviado correctamente", {
                variant: "success",
            });
            setLoading(false);
        } catch (err) {
            msg.current.enqueueSnackbar("Ocurrio un error al enviar el correo", {
                variant: "error",
            });
            setLoading(false);
        }
    }
    /**
     * Envia la petición de los clientes para agregar sus membresías
     * @param {*} clients 
     * @param {*} isNew 
     */
    const uploadFromCsv = (clients) => {
        clients.map((client, key) => {
            try {

                addMemb2(client.newClient, { membershep: client.membershep }, client.order, client.isNew, true, client.systemName, null, false)
            } catch (error) {
                console.log(error);
            }

        })

    }
    /**
    * Agrega una nueva membresía
    * Si es nuevo usuario isNew es true
    * Si incluye información de pago addOrder es true
    * @param {*} client 
    * @param {*} member 
    * @param {*} order 
    * @param {*} isNew 
    * @param {*} addOrder 
    * @param {*} systemName 
    */
    const addMemb2 = async (client, member, order, isNew, addOrder, systemName, file, validateFile = true) => {


        if (!validateClient(client, isNew)) {
            return false;
        }

        if (!systemName) {
            msg.current.enqueueSnackbar(`Debes elegir un sistema`, {
                variant: "warning",
            });
            return false;
        }

        if (!member.membershep) {
            msg.current.enqueueSnackbar(`Debes elegir una membresía`, {
                variant: "warning",
            });
            return false;
        }

        if (!file && validateFile) {
            msg.current.enqueueSnackbar(`Debes adjuntar un comprobante`, {
                variant: "warning",
            });
            return false;
        }

        if (addOrder) {
            if (!validateOrder(order)) {
                return false;
            }
        }
        /**
         * Objeto para enviar la alta de la membresia
         */
        setLoading(true)
        setOpen(false)
        const formData = new FormData();

        formData.append('file', file);
        formData.append('refer', currentUser);
        formData.append('systemName', systemName);
        formData.append('username', client.user_nicename);
        formData.append('membershep', member.membershep);


        /*let objSend = {
            refer: currentUser,
            username: client.user_nicename,
            systemName: systemName,
            membershep: member.membershep
        } */

        /**
         * Si el cliente es nuevo se agrega al objeto los datos del cliente
         * Se darán de alta local y en el wp
         */
        if (isNew) {
            //objSend.newClient = client;
            formData.append('newClient', JSON.stringify(client));
        }

        /**
         * Si se agrega información de pago
         * Envia los datos para alta local
         */


        if (addOrder) {
            // objSend.order = order;

            formData.append('order', JSON.stringify(order));
        }


        try {
            /** Crea los headers para enviar el archivo */
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            }
            const { data } = await api.patch('api/client/addclient', formData, config);

            console.log(data);

            if (data.status == 'Success') {
                msg.current.enqueueSnackbar(`Membresía agregada con éxito`, {
                    variant: "success",
                });
            } else {
                msg.current.enqueueSnackbar(`Ocurrio un error al agregar membresía`, {
                    variant: "error",
                });
            }

        } catch (err) {
            console.log(err);
            msg.current.enqueueSnackbar(`Ocurrio un error al inesperado`, {
                variant: "error",
            });
        }

        setReload(!reload)

    }
    /**
     * Regresa el titulo de la página
     * @returns 
     */
    const getTilte = (currType)=>{
       
        if(currType == 1) return "Membresías";
        if(currType == 2) return "Diplomados";
        if(currType == 3) return "Cursos";

        return "Otros";
    }

    const membTypes = [
        {value: [1], label: 'Membresias', perm: 'Ver_Membresias'},
        {value: [2], label: 'Diplomados', perm: 'Ver diplomados'},
        {value: [3], label: 'Cursos', perm: 'Ver cursos'}
    ]

    const getMembTypes = ()=>{

        let tempMembTypes = [];
        membTypes.map((membType)=>{
            if(validaPermisos(membType.perm)){
                tempMembTypes.push(membType)
            }
        })
        if(tempMembTypes.length > 0 && !currentType){
            setCurrentType(getAllMembTypesId());
        }
       
        return tempMembTypes;
    }

    const getAllMembTypesId = ()=>{

        let tempMembTypes = [];
        membTypes.map((membType)=>{
            if(validaPermisos(membType.perm)){
                tempMembTypes = [...tempMembTypes,...membType.value]
            }
        })
       
        return tempMembTypes;
    }

    return (
        <>
            <Loading open={loading} />

            <SnackbarProvider ref={msg} maxSnack={10} />

            {
                showCredit ?
                    <StatusBar
                        statusCount={statusCreditCount}
                    />
                    :
                    <StatusBar
                        statusCount={statusCount}
                    />
            }


            <Grid
                container
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{
                    minHeight: '100%',
                    margin: 0,
                    width: '100%',
                }}
            >

                {
                    !showCredit ?

                        <Grid item xs={12} sm={12} md={12} lg={12} style={{
                            minHeight: '100%',
                            width: '100%'
                        }}>
                            <ClientsTableV2
                                onFilter={getFilteredClients}
                                title={title}
                                clients={clients}
                                systems={systemListData}
                                permissions={permissions}
                                theme={theme}
                                dateIni={dateIni}
                                dateEnd={dateEnd}
                                setDateIni={setDateIni}
                                setDateEnd={setDateEnd}
                                onEdit={editValue}
                                onUpdateMemb={onUpdateMemb}
                                onDelete={deleteMemb}
                                onUpload={onUploadFile}
                                sendEmail={setUsersTo}
                                levelList={levelList}
                                countByStatus={countByStatus}
                                isEditable
                                setReload={setReload}
                                reload={reload}
                                msg={msg}
                                setLoading={setLoading} 
                                setCurrentType={setCurrentType}
                                membTypes={getMembTypes()}
                                allTypes={getAllMembTypesId()}
                            />
                        </Grid>
                        :
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{
                            minHeight: '100%',
                            width: '100%'
                        }}>
                            <PaymentsTable
                                title={"Pagos con tarjeta"}
                                clients={creditClients}
                                levelList={levelList}
                                onEdit={editValue}
                                onUpload={onUploadFile}
                                onDelete={deleteMemb}
                                onUpdateMemb={onUpdateMemb}
                                permissions={permissions}
                                theme={theme}
                                systems={systemListData}
                                sendEmail={setUsersTo}
                                dateIni={dateIni}
                                dateEnd={dateEnd}
                                setDateIni={setDateIni}
                                setDateEnd={setDateEnd}
                                countByStatus={countByStatus}

                            />
                        </Grid>

                }
            </Grid>

            <ClientsModal
                title={"Agregar membresía"}
                open={open}
                setOpen={setOpen}
                onSave={addMemb2}
                levelList={levelList}
                systemList={systemList}
                theme={theme}
            />

            <ClientCsv
                open={openCsv}
                setOpen={setOpenCsv}
                theme={theme}
                currentUser={currentUser}
                onSave={uploadFromCsv}
            />

            <EmailModal
                title={"Envio de correo"}
                open={openEmail}
                setOpen={setOpenEmail}
                correos={emailList}
                send={sendEmail}
                to={toList}
                dialogView={msg}
            />


            <SpeedDial

                theme={theme}
                ariaLabel="Acciones"
                sx={{ position: 'fixed', bottom: 16, right: '48%' }}
                icon={<SpeedDialIcon />}
                FabProps={{
                    sx: {
                        bgcolor: `${theme.palette.primary.main}`,
                        '&:hover': {
                            bgcolor: `${theme.palette.primary.main}`,
                        }
                    }
                }}
            >
                {actions.map((action) => {
                    if (validaPermisos(action.permission)) {
                        return (
                            <SpeedDialAction
                                theme={theme}
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={action.click}
                                style={{ color: '#000000' }}
                            />
                        )
                    }

                })}
            </SpeedDial>

        </>

    )
}

export default Clientes;