import { createTheme, CssBaseline } from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import Permisos from "./pages/Permisos";
import Principal from "./pages/Principal";
import Usuarios from "./pages/Usuarios";
import BarComponent from "./components/BarComponent";
import Login from "./pages/Login";
import { useCookies } from 'react-cookie';
import { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import Perfiles from "./pages/Perfiles";
import GestionPermisos from "./pages/GestionPermisos";
import { green } from "@material-ui/core/colors";
import PerfilesUsuarios from "./pages/PerfilesUsuarios";
import Clientes from "./pages/Clientes";
import Cookies from "universal-cookie";

const UsuariosRouter = withRouter(Usuarios);
const PrincipalRouter = withRouter(Principal);
const PermisosRouter = withRouter(Permisos);
const GestionPermisosRouter = withRouter(GestionPermisos);
const PerfilesRouter = withRouter(Perfiles);
const BarComponentrouter = withRouter(BarComponent);
const LoginRouter = withRouter(Login);



const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3ac7ff',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#6b8aa0',
      contrastText: '#000000',
    },
    success: green,

    warning: {
      main: '#f5a522',
    },
  },

  typography: {
    /*allVariants: {
      color: "white"
    },*/
    fontFamily: [
      'Nunito',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  background: {
    default: "#0000000"
  },
  indicator: {
    backgroundColor: "black"
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS

        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          // border:'1px solid #6b8aa0',
        },
      },
    }
  },
});

theme.typography.h3 = {
  fontFamily: 'Arial',
  textAlign: "center",
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.4rem',
    textAlign: "center"
  },
};

theme.typography.subtitle1 = {
  fontFamily: 'Arial',
  textAlign: "center",
  fontWeight: 600,
  color: "white"
};




const App = () => {
  const [cookies, removeCookie] = useCookies(['token']);

  const { decodedToken, isExpired } = useJwt(cookies?.token);
  const [isAuth, setIsAuth] = useState(false);
  const [profile, setProfile] = useState([]);
  const [systemNames, setSystemNames] = useState([]);
  const [countries, setCountries] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {


  }, []);


  useEffect(() => {

    if (decodedToken) {
      setIsAuth(true);

      let { userInfo } = decodedToken;

      setProfile(userInfo.roles);
      setSystemNames(userInfo.systemNames);
      setCurrentUser(userInfo.username);
      setCountries(userInfo.countries);


      if (isExpired) {
        removeCookie('token');

        alert("Su sesión ha caducado");

      }
    } else {
      setIsAuth(false);

    }
  }, [decodedToken, isAuth, isExpired]);


  const checkPermission = (permission) => {

    return profile.indexOf(permission) >= 0;
  }
  return (

    <>

      <ThemeProvider theme={theme}>

        <CssBaseline />



        <Router>
          {isAuth ? <BarComponentrouter /> : ""}
          <Switch>

            <Route path="/perfiles" render={(props) => {
              if (isAuth) {
                if (checkPermission("Perfiles_Agregar") || checkPermission("Perfiles_eliminar")) {
                  return <PerfilesRouter />;
                } else {
                  return "No tienes permisos para ver esta sección";
                }
              } else {

                props.history.push('/login');
              }

            }} />


            <Route path="/permisos" render={(props) => {
              if (isAuth) {
                if (checkPermission("Permisos_Agregar")) {
                  return <GestionPermisosRouter />;
                } else {
                  return "No tienes permisos para ver esta sección";
                }
              } else {
                props.history.push('/login');
              }

            }} />


            <Route path="/RolesyPermisos" render={(props) => {
              if (isAuth) {
                if (checkPermission("Permisos_Agregar")) {
                  // console.log("estan en rolesy permisos");
                  return <PerfilesUsuarios systemList={systemNames} />;

                } else {
                  return "No tienes permisos para ver esta sección";
                }
              } else {
                props.history.push('/login');
              }

            }} />
            <Route path="/support-chat" render={(props) => {
              if (isAuth) {
                if (checkPermission("Chat de soporte")) {
                  window.open(`${process.env.REACT_APP_SUPPORT_URL}/support?jwt=${cookies.token}`);
                  props.history.push('/login');
                } else {
                  return "No tienes permisos para ver esta sección";
                }
              } else {
                props.history.push('/login');
              }

            }} />

            <Route path="/google-email" render={(props) => {
              if (isAuth) {

                window.open(`${process.env.REACT_APP_SUPPORT_URL}/email?jwt=${cookies.token}`);
                props.history.push('/login');

              } else {
                props.history.push('/login');
              }

            }} />

            <Route path="/chat-banner" render={(props) => {
              if (isAuth) {

                window.open(`${process.env.REACT_APP_SUPPORT_URL}/settings?jwt=${cookies.token}`);
                props.history.push('/login');

              } else {
                props.history.push('/login');
              }

            }} />


            <Route exact path="/" render={(props) => {

              { isAuth ? props.history.push('/principal') : props.history.push('/login') }
            }} />


            <Route path="/usuarios" render={(props) => {
              if (isAuth) {
                if (checkPermission("Usuarios_Modificar") || checkPermission("Usuarios_Eliminar") || checkPermission("Usuarios_Agregar")) {
                  return <UsuariosRouter />;
                } else {
                  return "No tienes permisos para ver esta sección";
                }

              } else {
                props.history.push('/login');
              }

            }} />


            <Route path="/principal" render={(props) => {
              if (isAuth) {

                if (checkPermission("Ver diplomados") || checkPermission("Ver cursos") || checkPermission("Ver_Membresias") || checkPermission("Revisar_Membresias") || checkPermission("Activar_Membresias")) {
                  // return <PrincipalRouter/>;
                  return <Clientes theme={theme} systemList={systemNames} currentUser={currentUser} permissions={profile} countries={countries} />;
                } else {
                  return "No tienes permisos para ver esta sección";
                }

              } else {
                props.history.push('/login');
              }

            }} />

            <Route path="/clientes" render={(props) => {
              if (isAuth) {

                if (checkPermission("Ver_Membresias") || checkPermission("Revisar_Membresias") || checkPermission("Activar_Membresias")) {
                  //return <Clientes theme={theme} systemList={systemNames} currentUser={currentUser} permissions={profile}/>;
                  return <PrincipalRouter />;
                } else {
                  return "No tienes permisos para ver esta sección";
                }

              } else {
                props.history.push('/login');
              }

            }} />



            <Route path="/login"
              render={(props) => {
                if (!isAuth) {
                  return <LoginRouter theme={theme} />;
                } else {
                  props.history.push('/principal');
                }
              }} />







          </Switch>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
