
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from "@material-ui/core/colors";


const EmailModal = ({ title, open, setOpen, correos, send, to, dialogView }) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [emailData, setEmailData] = React.useState({
        email: '',
        system: '',
        subject: '',
        toEmail: to,
        msg: ''
    });

    const [emailCurr, setEmailCurr] = React.useState({});
    const [file, setFile] = React.useState(null);


    React.useState(() => {
        setEmailData({
            email: '',
            system: '',
            subject: '',
            toEmail: to,
            msg: ''
        });
        setEmailCurr({});
        setFile(null);
    })
    const handleClose = () => {
        setOpen(false);
    };

    const setValue = (event) => {
        let currData = {
            ...emailData,
            [event.target.name]: event.target.value
        };
        setEmailData(currData);

    }

    const setEmailSys = (data) => {
        let currData = {
            ...emailData,
            email: data.id,
            system: data.systemName,
        };
        setEmailCurr(data);
        setEmailData(currData);
    }

    const sendEmail = () => {
        if (!emailData.msg) {
            dialogView.current.enqueueSnackbar("El mesaje es requerido", {
                variant: "error",
            });
            return false;
        }
        if (!emailData.subject) {
            dialogView.current.enqueueSnackbar("El asunto es requerido", {
                variant: "error",
            });
            return false;
        }
        if (!emailData.email) {
            dialogView.current.enqueueSnackbar("El correo es requerido", {
                variant: "error",
            });
            return false;
        }
        send(emailData, file);
        setEmailData({
            email: '',
            system: '',
            subject: '',
            toEmail: to,
            msg: ''
        });
        setEmailCurr({});
        setFile(null);
    }

    return (
        <div>

            <Dialog
                fullScreen={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"

            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Envio de correo
                    </DialogContentText>

                    <Box sx={{ m: 2 }} >
                        <TextField
                            autoFocus
                            name="subject"
                            margin="dense"
                            id="subject"
                            label="Asunto"
                            value={emailData.subject}
                            onChange={(e) => setValue(e)}
                            fullWidth
                            variant="standard"
                        />
                    </Box>
                    <Box sx={{ m: 2 }} >
                        <InputLabel id="email">Correo (from)</InputLabel>
                        <Select
                            labelId="email"
                            id="email"
                            name="email"
                            fullWidth
                            label="email"
                            value={emailCurr}
                            onChange={(e) => { setEmailSys(e.target.value) }}
                            input={<OutlinedInput label="Correo" />}
                        >
                            {correos?.map((email) => {
                                return (<MenuItem key={email.id} value={email}>{email.name}</MenuItem>)

                            })}

                        </Select>

                    </Box>
                    <Box sx={{ m: 2 }} >
                        <TextField
                            autoFocus
                            name="msg"
                            margin="dense"
                            id="msg"
                            label="Mensaje"
                            multiline
                            maxRows={30}
                            value={emailData.msg}
                            onChange={(e) => setValue(e)}
                            fullWidth
                            variant="standard"
                        />
                    </Box>
                    <Box sx={{ m: 2 }} >
                        <Button
                            variant={file ? "contained" : "outlined"}
                            component="label"
                            fullWidth
                            style={file ? { background: green[500], color: '#FFFFFF' } : {}}
                            color={file ? "success" : "secondary"}
                            endIcon={file ? <CheckCircleIcon /> : ''}
                        >
                            {file ? file.name : "Adjuntar archivo"}
                            <input
                                type="file"
                                hidden
                                onChange={(e) => { setFile(e.target.files[0]); console.log(e.target.files[0]); }}
                            />
                        </Button>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        cancelar
                    </Button>
                    <Button onClick={sendEmail} autoFocus>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default EmailModal;