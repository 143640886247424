import TablaGeneral from "../../components/TablaGeneral";
import {  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, InputLabel, MenuItem, Paper, Select, TextField, useTheme } from "@material-ui/core";
import { Box } from "@mui/system";

import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from "react";
import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";
import api from "../../services/api";

import OutlinedInput from '@mui/material/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from "@material-ui/core/colors";
import EditIcon from '@mui/icons-material/Edit';

import Loading from "../../components/Loading";
import { SnackbarProvider } from "notistack";


const Usuarios = (props) =>{
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [cookies] = useCookies(['token']);
  const { decodedToken, isExpired } = useJwt(cookies?.token);
  const [users,setUsers] = useState([]);
  const [profiles,setProfiles] = useState([]);
  const [tokenJwt, setTokenJwt] = useState(cookies.token);

  const [email,setEmail] = useState(null);
  const [firstName,setFirstName] = useState(null);
  const [lastName,setLastName] = useState(null);
  const [password,setPassword] = useState(null);
  const [profile,setProfile] = useState(null);
  const [usersEdit,setUsersEdit] = useState([]);

  const [loading,setLoading] = useState(true);
  const [reload,setReload] = useState(false);
  const msg = useRef();

  useEffect(() => {
    async function getUsers(token){
        try{
            let req = {token:token}
            let {data} = await api.post('api/usuariolist',req);
            setUsers(data);
            let profTemp = await api.post('api/profilelist',req);
            setProfiles(profTemp.data);
            setLoading(false);
           
            
        }catch(err){
          errorMsg('Error al cargar usuarios','error');
          setLoading(false);
        }
    }

    if(tokenJwt){

      getUsers(tokenJwt);
    }
    setReload(false);
  },[tokenJwt,reload]);

  const handleOpen = (openVal) => {
    setOpen(openVal);
    setUsersEdit([]);
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
    setProfile("");
  }

  const errorMsg = (msg1,variant='warning') =>{
   
    msg.current.enqueueSnackbar(msg1, {
      variant: variant,
    });
  }
  const deleteUser = async (user) =>{
    setLoading(true);
     
        try{
            let req = {
                token:tokenJwt,
                email: user.email,
            }

            await api.post('api/usuariodel/',req);
            errorMsg('Usuario eliminado','success');
            setLoading(false);
            setReload(true);
        }catch(err){
         
          errorMsg("Error al eliminar usuario",'error');
          setLoading(false);
        }
      
     
  }

  const setUser = async (users) =>{
     
      let user = users;
      setUsersEdit([users]);
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setPassword("");
      setProfile(user.profile);

      setOpen(true);
   
  }

  const content = () => {
  
  
    return(
      <>
     
       <Box sx={{ m: 2 }}  boxShadow={10}>

       
          <Paper elevation={20} >
          
            <TablaGeneral 
                    title={"Usuarios"}
                    columns= {[
                    
                      { field: 'firstName', title: 'Nombre', width: 130 },
                      { field: 'lastName', title: 'Apellido', width: 130 },
                      { field: 'email', title: 'Correo', width: 130 },
                      { field: 'Profile.name', title: 'Perfil', width: 130 },
                  ]}
                  data={users}     
                  
                  options={{
                   
                    headerStyle: {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText
                    },
                  }}  

                  actions={[
                    {
                      icon: () => <DeleteIcon sx={{ color: red[300] }}/>,
                      tooltip: 'Eliminar usuario',
                      onClick: (event, rowData) => {deleteUser(rowData)}
                    },
                    {
                      icon: () => <EditIcon />,
                      tooltip: 'Editar usuario',
                      onClick: (event, rowData) => {setUser(rowData)}
                    }
                  ]}   
                  
              />
          </Paper>
          <Box sx={{ m: 2 }}  boxShadow={3}>
            <Grid container justifyContent="flex-end">
              
              <Fab color="primary" aria-label="add" onClick={() => {setUsersEdit([]);handleOpen(true);}}>
                <AddIcon />
              </Fab>
            </Grid>
          </Box>
       </Box>
      
      </>
    );
  }

  const createUser = async () => {
   
    if(!email){
        errorMsg("El correo es requerido");
        return false;
    }
    if(!firstName){
      errorMsg("El nombre es requerido");
      return false;
    }
    if(!lastName){
        errorMsg("El apellido es requerido");
        return false;
    }
    if(!password){
      errorMsg("La contraseña es requerida");
      return false;
    }
    if(!profile){
      errorMsg("Es necesario asignar un perfil");
      return false;
    }
    handleOpen(false);
    setLoading(true);
    let req = {
        token: tokenJwt,
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        profile: profile,
    }

    try{

      await api.post('api/usuario/',req);
      
      setLoading(false);
      setReload(true);
      errorMsg("Usuario creado",'success');
    }catch(err){
      errorMsg("Error al crear usuario",'error');
      setLoading(false);
   
    }
  }

  const updateUser = async () => {
   
    if(!email){
        errorMsg("El correo es requerido");
        return false;
    }
    if(!firstName){
      errorMsg("El nombre es requerido");
      return false;
    }
    if(!lastName){
        errorMsg("El apellido es requerido");
        return false;
    }
    if(!profile){
      errorMsg("Es necesario asignar un perfil");
      return false;
    }
    setOpen(false);
    setLoading(true);

    let req = {
        token: tokenJwt,
        email: email,
        firstName: firstName,
        lastName: lastName,
        profile: profile,
    }

    if(password){
      req.password =  password;
    }
    
    try{

      await api.post('api/usuarioupdate/',req);
      setLoading(false);
      setReload(true);
      errorMsg("Usuario actualizado",'success');
    }catch(err){
      errorMsg("Error al actualizar usuario",'error');
     
    }
  }
  return (
     <>
     
     {content()}
     <SnackbarProvider ref={msg} maxSnack={7} />
        <Loading open={loading}/>
     <Dialog onClose={() => handleOpen(false)} open={open}>
      <DialogTitle>Agregar usuario</DialogTitle>
      <DialogContent>
          <DialogContentText>
            Ingresa los datos solicitados para poder generar un nuevo usuario
          </DialogContentText>
          <Box sx={{ m: 2 }} >

              <TextField
                autoFocus
                margin="dense"
                id="firstName"
                label="Nombre de usuario"
                value={firstName}
                onChange = {(e) => setFirstName(e.target.value)}
                fullWidth
                variant="standard"
              />
          </Box>
          <Box sx={{ m: 2 }} >

              <TextField
                autoFocus
                margin="dense"
                id="lastname"
                label="Apellidos"
                value={lastName}
                onChange = {(e) => setLastName(e.target.value)}
                fullWidth
                variant="standard"
              />
          </Box>
         
          <Box sx={{ m: 2 }} >

              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Correo"
                value={email}
                onChange = {(e) => setEmail(e.target.value)}
                fullWidth
                variant="standard"
              />
          </Box>
          <Box sx={{ m: 2 }} >
           <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Contraseña"
            type="password"
            value={password}
            onChange = {(e) => setPassword(e.target.value)}
            fullWidth
            variant="standard"
          />
          </Box>
          <Box sx={{ m: 2 }} >
          <InputLabel id="perfilLabel">Perfil</InputLabel>
            <Select
                labelId="perfilLabel"
                id="Perfil"
                fullWidth
                label="Perfil"
                value={profile}
                onChange={(e) => {setProfile(e.target.value)}}
                input={<OutlinedInput label="Perfil" />}
            >
               {profiles.map((prof) => {
                  return( <MenuItem key={prof.id} value={prof.id}>{prof.name}</MenuItem>)
                
                })}
                
            </Select>
         
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleOpen(false)}>Cancel</Button>
          {
            usersEdit.length > 0 ? 
            <Button variant="contained" style={{color: theme.palette.primary.contrastText,background: theme.palette.primary.main}} onClick={() => updateUser()}>Guardar</Button>
              :
            <Button variant="contained" style={{color: theme.palette.primary.contrastText,background: theme.palette.primary.main}} onClick={() => createUser()}>Agregar</Button>

          }
         
        </DialogActions>
    </Dialog>
     </>
  );
}

export default Usuarios;
