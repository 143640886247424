import { Fab, Grid } from "@material-ui/core";
import Add from "@mui/icons-material/Add";
import { useEffect, useRef, useState } from "react";
import ProfileModal from "../../components/ProfileModal";
import ProfileTable from "../../components/ProfileTable";
import api from "../../services/api";
import { SnackbarProvider } from "notistack";
import Cookies from "universal-cookie";
import { useCookies } from "react-cookie";

/**
 * Vista para la modificación de permisos
 * @returns 
 */
const PerfilesUsuarios = ({theme,userEmail,systemList}) =>{
    /**
     * Variable que recibirá el objeto de perfiles
     * incluye sus permisos
     */
    const [profiles,setProfiles] = useState([]);
    /**
     * Variable que contiene la lista de permisos
     */
    const [permissions,setPermissions] = useState([]);
    /**
     * Variable que contiene la lista de perfiles
     */
    const [systemNames,setSystemNames] = useState([]);
    /**
     * Variable que contiene la lista de perfiles
     */
     const [countries,setCountries] = useState([]);
    /**
     * Variable para mostrar y coultar el modal
     */
    const [openModal,setOpenModal] = useState(false);
    /**
     * Variable para guardar el perfil seleccionado
     */
    const [currentProfile,setCurrentProfile] = useState(null);
    /**
     * Variable pararecargar la tabla sin recargar la página
     */
    const [reload,setReload] = useState(false);
    /**
     * Variable para mostrar la página de carga
     */
    const [isLoading,setIsLoading] = useState(true);
    /**
     * Notificaciones
     */

    const msg = useRef();


    /**
     * Se ejecuta al inicio
     */
    useEffect(()=>{
        /**
         * Consulta los perfiles 
         * El objeto contiene los permisos asignados a cada uno
         */
        async function getProfiles(){

            const {data} = await api.get("/profile/all");
            /**
             * Valida el estatus Success
             * sino manda un error al usuario
             */

            
            if(data.status == 'success'){

                setProfiles(data.profiles);
               
            }else{
                
            }
            
        }
        /**
         * Consulta los permisos 
         * Son necesarios en caso de querer asignar nuevos permisos
         */
        async function getPermissions(){
            const {data} = await api.get("/perm/list");
            if(data.status == 'success'){
                setPermissions(data.permissions);
            }else{

            }
        }
        /**
         * Consulta los sistemas 
         * Son necesarios en caso de querer asignar un sistema
         */
        async function getSystemNames(){
            const {data} = await api.post("/system/list");

   
            if(data.status == 'Success'){

                setSystemNames(data.systems)
            }else{

            }
        }
        /**
        * Consulta los sistemas 
        * Son necesarios en caso de querer asignar un sistema
        */
       async function getCountries(){
           const {data} = await api.get("/country/list");

        //console.log(data)
           if(data.status == 'Success'){

                setCountries(data.countries)
           }else{

           }
       }
         
        getProfiles();
        getPermissions();
        getSystemNames();
        getCountries();
    },[reload])

    /**
     * Elimina el perfil por id
     * @param {*} profileData 
     */
    const deleteProfile = async (profileData) =>{
        

        if (window.confirm(`El perfil ${profileData.name} se elminiará ¿Deseas continuar?`)) {
            const {data} = await api.put("/profile/del",{id:profileData.id});
            //console.log(data);
            if(data.status == 'success'){
                msg.current.enqueueSnackbar("Perfil eliminado", {
                    variant: "success",
                  });
                  setOpenModal(false);
                setReload(!reload);
            }else{

            }
        }
        

    }
    /**
     * Guarda el perfil seleccionado para su edición
     * @param {*} profileData 
     */
    const onEditProfile =  (profileData) =>{
        setOpenModal(true);
        setCurrentProfile(profileData);
    }

    /**
     * Agrega los datos como un nuevo perfil
     * 
     */
    const addProfile = async (newProfile,perm,sysNames,countr) => {
        /**
         * Prepara el objeto de perfil con los permisos
         */
        let request = {
            profileObj: newProfile,
            permissions: perm,
            systemNames: sysNames,
            countries: countr
        }

        /**
         * Si contiene un id entonces se envia para editar
         * en lugar de agregar
         */
        if(newProfile.id){
            request.id = newProfile.id;
        }
        
        const {data}  = await api.post("/profile/add",request);
        
        

        if(data.status == 'success'){
            msg.current.enqueueSnackbar("Perfil actualizado exitosamente", {
                variant: "success",
                });
            setOpenModal(false);
            setReload(!reload);
            
           /**const newToken  = await api.post("/updatePermissions",{email:userEmail });

            setCookie('token', newToken.data.token, { path: '/' });
            const cookies = new Cookies();
            cookies.set('token2', newToken.data.token, { path: '/' }); */ 

        }else{
            msg.current.enqueueSnackbar("Error al actualizar perfil", {
                variant: "error",
              });
        }

    }
    return(
        <>
            <SnackbarProvider ref={msg} maxSnack={10} />

            <ProfileModal
                title="Agregar perfil"
                open={openModal}
                setOpen={setOpenModal}
                onSave={addProfile}
                permissions={permissions}
                systemNames={systemNames}
                countries={countries}
                theme={theme}
                newProfile={currentProfile}
            />

            <Grid
                container 
                spacing={4}
                justifyContent="center"
                style={
                    {margin: 0,
                    width: '100%',
                    minHeight: '100vh'
                }}
            >

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <ProfileTable 
                        profiles={profiles}
                        onDelete={deleteProfile}
                        onEdit={onEditProfile}
                        
                    />
                </Grid>
            
            </Grid>

            
            <Fab 
                theme={theme}
                color="primary" 
                aria-label="add"
                style={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => {setOpenModal(true); setCurrentProfile(null);}}
            >
                <Add />
            </Fab>
        </>
    )
}

export default PerfilesUsuarios;