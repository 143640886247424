import { Box, TextField, Typography } from "@material-ui/core";


const TableField = ({value,width=200,isMultiline }) => {
    return(
        <Box sx={{ m: 2 }} >
            {!isMultiline ? 
                <Typography 
                    style={{width:width, fontSize: 15,}}
                >
                    {value ? value: 'N/A'}
                </Typography>
                :
                <TextField 
                    style={{width:width}}
                    variant="outlined" 
                    multiline
                    rows={1}
                    defaultValue={value}
                    disabled
                
                />
            }
           
        </Box>
    )
}

export default TableField;