import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useEffect, useState } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, TextField } from '@material-ui/core';
import MultiSelect from '../MultiSelect';


export default function ProfileModal({title,open,setOpen,onSave,theme,permissions,systemNames,countries,newProfile}) {

    /**
     * Revisa si newProfile contiene datos
     * Si es asi los asigna a la vista para editar
     */
    useEffect(()=>{
    
        if(newProfile){
            if(newProfile.id){

               // console.log(newProfile)
                /**
                 * Crea una lista con solo los id de permisos que ya tiene el perfil
                 * guarda la lista en setCurrentPerm
                 */
                const newListP = newProfile.Permissions.map((perm)=>perm.id);
                setCurrentPerm(newListP);

                /**
                 * Crea una lista con solo los id de sistemas que ya tiene el perfil
                 * guarda la lista en setCurrentSys
                 */
                const newListSys = newProfile.WordpressSystems.map((sys)=>sys.id);
                setCurrentSys(newListSys);

                const newListCoun = newProfile.Countries.map((country)=>country.id);
                setCurrentCountry(newListCoun)

                setProfile({
                    id:newProfile.id,
                    name:newProfile.name,
                    description: newProfile.description
                })
            }
        }else{
            setProfile({
                name:'',
                description:''
            })
        }
    },[newProfile]);
    /**
     * Variable para el nuevo perfil
     */
    const [profile, setProfile] = useState({
        name:'',
        description:''
    });

    /**
     * Variable para la lista de permisos ya asignados
     */
     const [currentPem, setCurrentPerm] = useState([]);
     /**
      * Variable para la lista de sistemas ya asignados
      */
     const [currentSys, setCurrentSys] = useState([]);
      /**
      * Variable para la lista de paises ya asignados
      */
    const [currentCountry, setCurrentCountry] = useState([]);

    /**
     * Cierra el modal
     */
    const handleClose = () => {
        setOpen(false);
    };
    /**
     * Recibe el evento y asigna el valor
     * dentro del objeto profile
     * @param {*} e 
     */
    const handleChange= (e) => {
        const event = e.target;

        setProfile({
            ...profile,
            [event.name]: event.value,
        })

    }
    /**
     * Detecta cuando el multiselect de perfiles cambia
     * @param {*} list 
     */
    const handleChangePermission = (list) =>{
        setCurrentPerm(list);
    }
     /**
     * Detecta cuando el multiselect de sistemas cambia
     * @param {*} list 
     */
    const handleChangeSystems = (list) =>{
        setCurrentSys(list)

    }

    /**
     * Detecta cuando el multiselect de paises cambia
     * @param {*} list 
     */
    const handleChangeCountries = (list) =>{
        setCurrentCountry(list)

    }
    return (
    <>

        <Dialog
            disableEnforceFocus 
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
        <DialogTitle  id="customized-dialog-title" onClose={handleClose}>
            {title}
        </DialogTitle >

        <DialogContent dividers>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField 
                        fullWidth 
                        value={profile.name}
                        label="Nombre del perfil" 
                        name="name"
                        variant="outlined" 
                        onChange={handleChange}
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField 
                        fullWidth 
                        value={profile.description}
                        label="Descripcion" 
                        name="description"
                        variant="outlined" 
                        onChange={handleChange}
                    />

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MultiSelect
                        title="Paises asignados"
                        theme={theme}
                        list={countries}
                        onChange={handleChangeCountries}
                        initSelect={currentCountry}
                   />

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                   <MultiSelect
                        title="Permisos"
                        theme={theme}
                        list={permissions}
                        onChange={handleChangePermission}
                        initSelect={currentPem}
                   />

                </Grid>
               
     
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <MultiSelect
                        title="Sistemas asignados"
                        theme={theme}
                        list={systemNames}
                        onChange={handleChangeSystems}
                        initSelect={currentSys}
                   />

                </Grid>

                
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button theme={theme} variant="outlined" color="error" onClick={handleClose}>
                Cancelar
            </Button>
            <Button theme={theme} variant="contained" color="primary"  onClick={() => onSave(profile,currentPem,currentSys,currentCountry)}>
                Guardar
            </Button>
        </DialogActions>
        </Dialog>
    </>
    );
}