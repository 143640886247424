import { Grid} from "@mui/material";
import CustomListPer from "../../components/CustomListPer";
import TablaGeneral from "../../components/TablaGeneral";
import  { useState, useEffect, useRef } from 'react';
import { Box } from "@mui/system";
import {  Button, Typography, useTheme } from "@material-ui/core";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { green } from "@material-ui/core/colors";
import { useCookies } from 'react-cookie';
import { useJwt } from "react-jwt";
import api from "../../services/api";
import Loading from '../../components/Loading';
import { SnackbarProvider } from 'notistack';

const GestionPermisos = (props) =>{
    const [itemsSelected, setItemsSelected] = useState([]);
    const [itemsNotSelected, setItemsNotSelected] = useState([]);
    const [items,setItems] = useState();
    const theme = useTheme();

    const [cookies] = useCookies(['token']);
    const { decodedToken, isExpired } = useJwt(cookies?.token);
    const [profiles,setProfiles] = useState([]);
    const [tokenJwt] = useState(cookies.token);
    const [newPerms, setNewPerms] = useState([]);
    const [currentProf,setCurrentProf] = useState(0);

    const [loading,setLoading] = useState(true);
    const [reload,setReload] = useState(false);
    const msg = useRef();

    useEffect(() => {
      async function getProfiles(token){
          try{
              let req = {token:token}
              let {data} = await api.post('api/profilelist',req);
            
              setProfiles(data);
              setLoading(false);
          }catch(err){
            
            setLoading(false);
            msg.current.enqueueSnackbar("Error al cargar perfiles", {
              variant: "error",
            });
          }
      }
      async function getPermissions(token){
        try{
            let req = {token:token}
            let {data} = await api.post('api/permissionlist',req);
           
            setItems(data);
        }catch(err){
          console.log(err);
        }
    }
      
      if(tokenJwt){
  
        getProfiles(tokenJwt);
        getPermissions(tokenJwt);
      }
      setReload(false);
    },[tokenJwt,reload]);
    const selectPerfil = (perfilData) => {
        
      
        setCurrentProf(perfilData.id);
        let permisos = perfilData.Permissions;
        let arrPerm =  items.filter( ( el ) => {
            let ind = permisos.map(function(e) { return e.id; }).indexOf(el.id);
            return ind < 0;
        } );
   
        setItemsSelected(permisos);
        setItemsNotSelected(arrPerm);
    } 
     useEffect(() => {
      
    },[itemsNotSelected,itemsSelected]);
    
    const savePermissions = (newPermi) =>{
       
        setNewPerms(newPermi);
    }

    const updatePermissions = async () =>{

      setLoading(true);
        let permArr = [];
        for(let i = 0;i < newPerms.length;i++){
            permArr.push(newPerms[i].id);
        }

        try{
            let req = {
                token:tokenJwt,
                profile: currentProf,
                permissions: permArr
            }
           
           await api.post('api/relationPerMassive/',req);
           setLoading(false);
           setReload(true);
           errorMsg("Permisos actualizados","success");
        }catch(err){
          console.log(err);
          errorMsg("Error al agregar permisos","error");
        }
    }
    const errorMsg = (msg1,variant='warning') =>{
   
      msg.current.enqueueSnackbar(msg1, {
        variant: variant,
      });
    }

  const content = () => {
    
    return(
      <>
   <SnackbarProvider ref={msg} maxSnack={7} />
        <Loading open={loading}/>
     <Box sx={{ m: 2 }}  boxShadow={10}>

       
        
      <Grid container direction={'row'} spacing={2}>
        <Grid item  xl={6} md={6} sm={12} xs={12}>
           
              
              <TablaGeneral
                title="Perfiles"
                columns= {[
                    { field: 'id', title: 'ID', width: 70 },
                    { field: 'name', title: 'Perfil', width: 130 },
                    { field: 'description', title: 'Descripción', width: 130 },
                ]}
                data={profiles}      
                options={{
                 
                  headerStyle: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                  },
                 
                }}    
               
                onRowClick={(event, rowData) => selectPerfil(rowData)} 
    
              />
           
        </Grid>
        <Grid item  xl={6} md={6} sm={12} xs={12}>
            <Typography variant='h3'>
                Permisos
            </Typography>
           <CustomListPer
                itemsSelected = {itemsSelected}
                itemsNotSelected = {itemsNotSelected}
                setPermissions={savePermissions}
            />
        </Grid>
        <Grid 
            container  
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing = {3}
        >   
               
                <Grid 
                   item xl={12} md={12} sm={12} xs={12}
                >

                    <Button 
                        variant="contained"  
                        color="primary"
                        onClick={() => updatePermissions()}
                    >
                        Guardar
                    </Button>

                </Grid>
        </Grid>
       
    </Grid>
    
    
    
    </Box>
           

           
     
       
      </>
    );
  }
  return (
     <>
 {content()}
     
     </>
  );
}

export default GestionPermisos;
