import { Fab, Grid, IconButton, Paper } from "@material-ui/core";
import React, { useState, useCallback, useRef, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { Add } from "@material-ui/icons";
import api from "../../services/api";

/**
 * Visor de imagenes 
 * Recibe un array con las imagenes
 * @returns 
 */
const ImageViewerModal = ({ member, theme, setReload, setOpen, msg, reload, setLoading, permissions }) => {
    /**
     * Variables para la cisualizacion de imagenes
     */
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    /**
     * Archivo para subir imagen
     */
    const [imagesArray, setImagesArray] = useState([]);

    const fileInput = React.useRef();


    useEffect(() => {
        async function getAllImages() {

            const arrImg = [];

            if (member.ticket?.includes('.pdf')) {
                const newPdfImage = await getPdfToImage(member.ticket);

                arrImg.push(newPdfImage);

            } else if(member.ticket){
                arrImg.push(member.ticket);

            }

            await Promise.all(member.ClientImages.map(async (img, key) => {

                if (img.imgUrl.includes('.pdf')) {
                    const newPdfImage = await getPdfToImage(img.imgUrl);

                    arrImg.push(newPdfImage);
                    return true;
                } else {
                    arrImg.push(img.imgUrl);
                    return true;
                }

            }));

            setImagesArray(arrImg);
        }
        getAllImages();
    }, [])

    /**
    * Metodo para convertir los pdf en imagenes
    */
    const getPdfToImage = async (pdfUrl) => {
        try {
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${window.pdfjsLib.version}/pdf.worker.mjs`;
            
            const pdf = await window.pdfjsLib.getDocument(pdfUrl).promise;
            const firstPage = await pdf.getPage(1);
            const scale = 1.5;
            const viewport = firstPage.getViewport({ scale });
            const canvas = document.createElement('canvas');
            document.getElementById("image-convas-row").append(canvas)
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            //canvas.style.display = 'none';
            await firstPage.render({ canvasContext: context, viewport }).promise;
           
            var img = canvas.toDataURL("image/png");

            return img;
            /*setImageUrl(canvas.toDataURL())
            
            const file = await fetch(pdfUrl).then(r => r.blob());
            const uri = URL.createObjectURL(file);
            var _PDF_DOC = await window.pdfjsLib.getDocument({ url: uri });
            var page = await _PDF_DOC.getPage(1);
            var viewport = page.getViewport(1);
            var canvas = document.createElement("canvas");
            document.getElementById("image-convas-row").append(canvas)

            var render_context = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport
            };

            canvas.width = viewport.width;
            canvas.height = viewport.height;
            canvas.style.display = 'none';
            await page.render(render_context);

            var img = canvas.toDataURL("image/png");

            return img;*/
        } catch (error) {
            console.log(error)
            alert(error.message);
        }
    }

    /**
     * Abre el visor de imagens
     */
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    /**
     * Cierra el visor de imagenes
     */
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    /**
     * Function para subir un nuevo archivo
     * @param {*} event 
     */
    const uploadNewFile = async (event, image) => {


        const file = event.target.files[0];
        const formData = new FormData();

        formData.append('upload', file);
        try {
            setLoading(true);
            setOpen(false);
            const { data } = await api.patch('/digital/upload/image', formData);

            try {

                const newImage = {
                    clientId: member.id,
                    imgUrl: data.url
                }

                const request = {
                    systemName: member.systemName,
                    id: image ? image.id : 0,
                    image: newImage
                }
                const resp = await api.post('/api/client/addDoc', request);

                //console.log(resp)
                msg.current.enqueueSnackbar("Imagen agregada correctamente", {
                    variant: "success",
                });
                setReload(!reload);

            } catch (err) {
                console.log(err);
                setReload(!reload);
            }
        } catch (err) {
            console.log(err);
            setReload(!reload);
        }

    }

    const checkPermission = (permission) => {

        return permissions.indexOf(permission) >= 0;
    }
    /**
     * Function para eliminar un archivo
     * @param {*} event 
     */
    const deleteFile = async (id) => {
        if (!window.confirm("¿Deseas eliminar esta imagen?")) {
            return;
        }
        try {

            setLoading(true);
            setOpen(false);
            const request = {
                systemName: member.systemName,
                id: id,
            }
            const resp = await api.put('/api/client/deleteDoc', request);

            //console.log(resp)
            msg.current.enqueueSnackbar("Imagen Eliminada", {
                variant: "success",
            });

            setReload(!reload);

        } catch (err) {
            console.log(err);
            setReload(!reload);
        }
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>

                <Paper elevation={2}>
                    <img
                        src={member.ticket}
                        onClick={() => openImageViewer(0)}
                        width="100%"
                        key={0}
                        style={{ margin: "2px" }}
                        alt=""
                    />

                </Paper>

            </Grid>
            {imagesArray?.map((img, index) => (
                <Grid item xs={12} sm={12} md={12} lg={12}>

                    <Paper elevation={2}>
             
                        <img
                            src={img}
                            onClick={() => openImageViewer(index )}
                            width="100%"
                            key={index}
                            style={{ margin: "2px" }}
                            alt=""
                        />


                        <Grid container justify="flex-end">

                            {/*<IconButton color="primary" aria-label="delete">
                                <UpgradeIcon />
                            </IconButton>*/}

                            {
                                checkPermission("Eliminar archivos")
                                &&
                                <IconButton onClick={() => deleteFile(img.id)} color="danger" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            }

                        </Grid>

                    </Paper>

                </Grid>

            ))}
            {
                (!isViewerOpen && checkPermission("Agregar archivos")) && (
                    <div>
                        <input
                            ref={fileInput}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={uploadNewFile}
                        />
                        <Fab
                            theme={theme}
                            color="secondary"
                            aria-label="add"
                            style={{ position: 'absolute', bottom: 16, right: '45%' }}
                            onClick={() => {
                                if (imagesArray.length > 3) {
                                    msg.current.enqueueSnackbar("Solo se permiten 3 archivos", {
                                        variant: "warning",
                                    });
                                } else { fileInput.current.click() }
                            }}
                        >
                            <Add />

                        </Fab>
                    </div>)
            }
            {isViewerOpen && (
                <ImageViewer
                    src={imagesArray}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                    closeOnClickOutside={true}
                />
            )}
            {/** Seccion para el renderizao de pdfs */}
            <div className="App">

                <div id="image-convas-row">

                </div>

            </div>
        </Grid>
    );
}

export default ImageViewerModal;